.gestion_bottom_right{
    display: flex;
    gap: 20px ; 
}

.popupresult{
    position: absolute;
    top: 20vh;
    left: 50%;
    width: auto;
    max-width:   800px;
    height: 400px;
    background-color: #FFF;
   border:  1px solid gray;
    transform: translateX(-50%);
    z-index: 2000;
    overflow-x: scroll;
    padding: 20px;
}
.displayflexsb{display: flex; justify-content: space-between;}
.colonnecom{
    &_com{font-size: 20px;  font-weight: bold ; }
    &_statut{font-size: 18px;  font-weight:500}
}
.divbilanleads{
    padding: 25px;
    height: 100vh;
    &_choices{
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }
}

.nombre{
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: #FFF;
    font-size: 18px ;
    text-align: center;
    background-color: #0068FF;
    margin-right: 25px ;
}


.containerselection{
    margin-right: 15px;

    &.wd125{ width: 125px;}
    &.wd150{ width: 150px;}
    input{height: 30px;
            padding: 0 6px;
            border: 1px solid lightgray;
            color:#101828 ;
            outline: none;}

    .labelinput{
        height: 20px;  line-height: 20px;   font-size: 16px;
    }
}

.selectchoice{
    &.wd125{ width: 125px;}
    &.wd150{ width: 150px;}
    &.wd140{ width: 140px;}
    &.wd180{ width: 180px;}
    height:  30px;
    margin-bottom: 0;
    
   
}

.gestion_bottom{
    display: flex;
    gap: 100px ;
    position: absolute;
    bottom: 0;
}
.gestion_choicetype{
    // border: 1px solid red ;
    width: 405px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.gestion_choiceperiode{
    width: 405px;
    button{ width: 405px; margin-bottom: 10px;}
}
.bouton{ 
         outline: none;  border-radius: 6px; height: 40px;  border: none;
         padding: 0 15px;  line-height: 40px;  color: #FFF;   cursor: pointer;
    
         &.vert{background-color: green;}
         &.rouge{background-color: red;}
         &.rougee{background-color: red;width: 400px;}
         &.bleue{ background-color: blue; width: 190px;} 
         &.bleu{font-size: 20px;  font-weight: bold;  background-color: blue; margin-bottom: 15px; }  
         &.selected{opacity: 0.2}
}
.ml20{ margin-left: 20px;}
.ml10{ margin-left: 10px;}

.scrolltable{
    height: calc(100vh - 800px); position: relative;      margin-bottom: 50px;
    overflow-y: scroll ;&::-webkit-scrollbar {display: none; } 
table thead{position: fixed; 
               &>:nth-child(1){ width: 180px;} &>:nth-child(2){ width: 300px;}     &>:nth-child(3){ width: 150px;} &>:nth-child(4){ width: 250px;} &>:nth-child(5){ width: 150px;}     &>:nth-child(6){ width: 250px;}   }
table tbody{margin-top: 25px; 
    tr{height: 20px;td{ padding: 5px 0; text-align: center; } &>:nth-child(1){ width: 180px;}  &>:nth-child(2){ width: 300px;}   &>:nth-child(3){ width: 150px;} &>:nth-child(4){ width: 250px;} &>:nth-child(5){ width: 150px;}     &>:nth-child(6){ width: 250px;}    } 
}
}
.partie1{
    display: flex;
    max-width: 1000px;
}

///////////////////////////////
///////////////////////////////
.btncampagne{
    margin-right: 10px;
    background-color: #F68B0A ;
    border: none;
    color: #FFF;
    height:28px;
    line-height:28px;
    cursor: pointer;
    border-radius: 5px;
    span{font-weight: bold;}
 }

 .rowleadsweek{td{text-align: center;}}

 .rowleadstable{background-color: #FFF; padding: 10px;th{padding: 3px;} }

.divtitretaloleadjour{
    display: flex;
    align-items: center;
    height: 50px; line-height: 50px;
    font-size: 28px;
    font-weight: 500;
    color: #101828;
    &_inputdate{
        margin-left: 25px;
        height: 30px;
        padding: 0 6px;
        border: 1px solid lightgray;
        color:#101828 ;
        outline: none;
    }
    &_btn{
        margin-left: 10px; outline: none;  border: 1px solid green; border-radius: 6px; height: 30px;
         line-height: 30px;  color: #FFF; background-color: green;  cursor: pointer;
    }
}
// td{padding: 0 5px;}

.btnperiode{
    margin-right: 10px;
    background-color: #0068FF ;
    border: none;
    color: #FFF;
    height:28px;
    line-height:28px;
    border-radius: 5px;
    cursor: pointer;
    &.peiodeselet{
        opacity: 0.5
    }
}

.total{
    display: inline-block;
    // width: 28px;
    height:28px;
    line-height:28px;
    background-color: red;
    color: #FFF;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-right: 10px;
    padding: 0 15px;

}

.containerdatacomcamps, .containerdatacamp{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px ;
    row-gap: 20px;
}

.datacom{
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    font-size: 22px;
    background-color:#FFF;
    color:  blue; 
    margin-bottom: 15px;
    span{display: inline-block;  margin-left: 15px;}
}
.datacamp{
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    font-size: 14px;
    background-color:#F68B0A;
    color: #FFF;
    span{display: inline-block;  margin-left: 15px;}  
}

.leadstop_input{
    padding-left: 36px;
    height: 40px;
    line-height: 40px;
    width: 230px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    outline: none;
    background-position: 10px 50%;  
    background-size: 20px;
    background-image: url("../../images/search.svg");
    background-repeat: no-repeat;
    margin-bottom: 25px;

}

.lignecontrat{
    margin-bottom: 8px;
    position: relative;
    &_delete{ cursor: pointer ; color:red ; position: absolute;  right: 5px; top:0; font-weight: bold;}
}