.dashboardfiles_container{
    height: calc(100vh - 50px);
}
.hrefdashfile{
    color: blue;
    text-decoration: underline;
    font-size: 13px;

}

.inputdashfile{
    width: 120px;
    border: 1px solid red;
    opacity: 0;
    position: absolute;
    left: 0;
    z-index: -1;
}

.loaderdashfile{
    display: inline-block;
    animation: rotation 2s infinite linear;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-position:center center;  background-repeat: no-repeat;  
    background-size: 18px;background-image: url(../../images/loading.svg);
}


.labelinputdashfile{
    z-index: -1;
      color: red;
      font-size: 13px;
      cursor: pointer;
      font-weight: bold;
      width: 20px;
      text-align: center;
      
  }

  
.dashboardfiles{
  
    display: flex;
    border-bottom: 1px solid gray;
    margin-bottom: 8px;

    &_compagnie{
        position:relative ;
        font-weight: bold;
        width: 160px;
        min-height: 80px;
    }
    &_gamme{
        font-weight: 700;
        font-size: 12px;
        width: 220px;
        height: 20px;
        line-height: 20px;
    }
    &_line{
        display: flex;
    }
    &_detail{position: relative; width: 120px; height: 20px; text-align: center; display: flex;
        justify-content: center; align-items: center;
        label{position:absolute;left:0;width:120px;height:20px;line-height: 20px;z-index: 99;}
        input{position: absolute;width:120px;opacity:0;z-index: 100;}
        
}
    &_delimage{
        position:absolute;
        top:30px;
        right: 80px;
        color:red ;
        font-weight: bold;
    }

    &_linetitres{
        display: flex;
        border-bottom: 1px solid gray;
        margin-bottom: 8px;
        & > :nth-child(1){     width: 160px; height: 20px; }
        & > :nth-child(2){   display: flex;
                                & > :nth-child(1){  width: 220px;} 
                                & > :nth-child(2){  width: 120px;font-size: 18px; font-weight: bold; text-align: center;}
                                & > :nth-child(3){  width: 120px;font-size: 18px; font-weight: bold; text-align: center;}
                                & > :nth-child(4){  width: 120px;font-size: 18px; font-weight: bold; text-align: center;}
                                & > :nth-child(5){  width: 120px;font-size: 18px; font-weight: bold; text-align: center;}
                                & > :nth-child(6){  width: 120px;font-size: 18px; font-weight: bold; text-align: center;}
                                & > :nth-child(7){  width: 120px;font-size: 18px; font-weight: bold; text-align: center;}

                            }
    }
}

.deldashfile{
    color: black;
    margin-left: 15px;

    cursor: pointer;
    display: inline-block;
    font-size: 9px;
    font-weight: bold;

}