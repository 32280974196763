
.divemails{  
    width: 734px;background-color: #ECF4FF;

}


.divmessages{ position: relative; 
    padding: 0 20px;width: 734px;    height:calc(100vh - 336px) ;background-color: #ECF4FF;
    overflow: scroll;&::-webkit-scrollbar {   display: none;}
}


.divmessagesfooter{width: 734px;height: 89px;background: #FFFFFF;display: flex;
    justify-content: flex-end;align-items: center;padding: 0 20px;
}


.messagecon{
    max-height: 100px;    position: relative;
    overflow-x: hidden;     overflow-y: scroll;
    &::-webkit-scrollbar { display: none; } 
}


.message{  width: 490px;
    &.messageto{margin-left: auto;  }
    &_content{
          max-height: 200px;  margin-bottom: 20px;    position: relative;
        overflow: hidden; 
        
    }
}

.message_attachments{    margin-top: 5px; }
//////////////////////////////////////////////////////////////////////////////////////////////



.message_top{width: 490px;display: flex;justify-content: space-between;align-items: center;height: 20px;
    &_left{font-family: 'Poppins';font-weight: 500; font-size: 14px;line-height: 20px;color: #344054;} 
    &_right{display: flex;
        div:first-child{    font-family: 'Poppins';font-weight: 500; font-size: 10px;line-height: 20px;  color: #667085;}
        div:nth-child(2){ height: 18px; font-family: 'Poppins'; font-style: normal; font-weight: 400;line-height: 20px;  font-size: 10px; 
                        padding-left:25px; background-position: left 6px center;  background-repeat: no-repeat;
                        background-size: 19px 10px; 
        } 
        .Delivery{ background-image: url(../../images/delivery.svg);color: #32D583; }
        .Open{ background-image: url(../../images/open.svg); color: #32D583;  }
        .Click{ background-image: url(../../images/open.svg); color: #32D583;  }
        .Bounce{ background-image: url(../../images/rejected.svg); color: #F97066;  }
        .SpamComplaint{ background-image: url(../../images/rejected.svg);  color: #F97066;   }
        .Send{ background-image: url(../../images/send.svg);  color : #667085 }
     }

    // div:nth-child(2){ font-family: 'Poppins';font-weight: 500; font-size: 10px;line-height: 20px;  color: #667085;} 
}

.message_content{ 
    position: relative;width: 490px;padding: 15px 15px  15px  56px;min-height: 50px;
    background-image: url(../../images/messageemail.svg);background-size: 36px 36px;
    background-position: 10px 10px;  background-repeat: no-repeat;
    &.messageto{background-color: #0068FF; border-radius: 8px 0px 8px 8px;
               div{font-family: 'Poppins'; color: #FFF;font-size: 14px;line-height: 20px; font-weight: 400; }
               div:first-child{font-family: 'Poppins'; font-weight: 600; } }
    &.messagefrom{ background-color: #FFF; border-radius: 0px 8px  8px 8px;
                    div{color:  #667085;font-size: 14px;line-height: 20px;}
                    div:first-child{ font-weight: bold; color: #344054;}  }
}


.inputmessage{
    width: 501px;height: 44px;background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;
    font-size: 16px;line-height: 44px;color: #667085;padding-left: 15px;

}




.messageattachementlink{
    font-style: normal;font-weight: 500;font-size: 12px;line-height: 18px;color: #FFF;
    display: inline-block ;  padding-left:15px ;  margin-right: 15px;
    background-image: url(../../images/messagelink.svg);
    background-position: 0px center;  background-repeat: no-repeat;
    background-size: 12px 12px;
}