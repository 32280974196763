#selectorigine{
  width: 93px;
  text-align: center;
  overflow: hidden;
}

#selectorigine{
  width: 105px;
  text-align: center;
  overflow: hidden;
}

#selectcommercial{
  width: 102px;
  text-align: center;
  overflow: hidden;
}

.ecrangestionfilterssearch{
    width: 1200px;
    margin: 30px 0;
    display: flex;
   justify-content: space-between;
}
.grpbtnstimes{
  position: relative;
}

.container_origines{
  position: relative;
  z-index: 2000;

}
.origines{ position: absolute; right: 0; top: 37px; background-color: #FFF;  border: 1px solid #D0D5DD;
max-height: 600px;
overflow: scroll;
&::-webkit-scrollbar {   display: none;}
}

.divequipes{ position: absolute; left:103px; top: 37px; background-color: #FFF;  border: 1px solid #D0D5DD;
  max-height: 600px;
  overflow: scroll;
  &::-webkit-scrollbar {   display: none;}
  }



.origine{ height: 40px; line-height: 40px; color: #D0D5DD; padding-left: 10px; padding-right: 10px; cursor: pointer;  border-bottom: 1px solid #D0D5DD;  }
.containertwocalendars{    z-index: 2000;
  position : absolute;
  top: 45px;
  left: 0px;}
.btnchoice{ 
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    font-size: 12px;
    outline: none;
    cursor: pointer; 
    background: #FFF ;
    border-top: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD;
    border-right: 1px solid #D0D5DD;
    border-left:none;
    color: #344054;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    text-align: left;

  
    &.first{ border-radius:  8px 0 0 8px ;  border-left: 1px solid #D0D5DD;}
  
    &.last{ border-radius:  0 8px 8px 0;  }
  
    &.selected{ background-color: #98A2B3; color:#FFF; }
   
  } 