



.contratwin3_texte2{
    
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #667085;
margin-bottom: 10px;
}



.contratwindocs_upload{
    display: flex;
    flex-wrap: wrap ;
    gap: 10px ;
    padding: 12px;


}