.sendmail_container{
    position: absolute;top:  0 ; padding: 55px 10px 0 10px;width: 734px;
    height:calc(100vh - 246px) ;margin: auto ;background-color:  #ECF4FF;
    overflow-y: scroll; &::-webkit-scrollbar { display: none; } 
}

.sendmail_top{
    position: absolute;left: 0px;  top: 0; right: 0;padding: 0 15px;
    height: 50px;line-height: 50px;background-color: #FFFFFF;color: #101828;font-size: 18px;
    overflow:hidden ;
    span{color: #667085; font-size: 14px; }
    img{position: absolute;right: 15px;top: 20px;cursor: pointer;}
  }

.sendmail_input{ width: 100%; height: 40px;background: #FFFFFF;border: 1px solid #EAECF0;
                        border-radius: 8px;   margin-bottom: 5px; margin-top: 5px; padding-left:10px ;}

.sendmail_textarea{width: 100%;background: #FFFFFF;border: 1px solid #EAECF0;border-radius: 8px;  
                        height:calc(100vh - 500px) ;color:  #667085;padding: 20px;outline: none;}

.sendmail_bottom{position: absolute;left: 0px;  bottom: 0; right: 0;  height: 89px; background: #FFFFFF;padding: 0  15px;  display: flex;
                  justify-content: space-between;  align-items: center;  }

.sendmail_bottom_right{display: flex;   justify-content: space-between; align-items: center;  }
.containerlistenamefiles{ height: 80px; display: flex; max-width: 500px; align-items: center;  flex-wrap: wrap;  overflow: hidden;  position: absolute; bottom: 5px; }

.inputmessageattachement_container{
            display: flex;align-items: center;justify-content: center;width: 48px;height: 48px; 
            background-image: url(../../images/iconattachement.svg);background-position: center;
            background-repeat: no-repeat;background-size: 20px  20px;
            input{border: none;outline: none;height: 44px;width:44px; opacity: 0;cursor: pointer;}
}

.divfilenameinliste{

  display: flex;
  width: 140px; 
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img{display: inline-block; margin-left: 3px; margin-right:10px;}

}
.filenameinliste{
 position: relative;
  display: inline-block;    width: 130px; background-color: #F2F4F7;border-radius: 6px;
  font-weight: 400;font-size: 14px;line-height: 20px ; height: 20px;color: #667085; padding: 0 5px;
  overflow: hidden  ;
}

.attachements_jauge{
 position: relative; width: 70px; height: 8px; border-radius: 4px ; background-color: #ECF4FF ;
}
.attachements_jauge_interne{
  position: absolute;  height: 8px; border-radius: 4px ; background-color:  #FF8001 ;
  &.error{background-color: #F04438;;  }
 }
   .messagefilesbig{
    position: absolute;
    right: 20px;
    bottom: 0;
      width: 450px;font-style: normal;
    font-weight: 400;
    font-size: 13px;color: #F04438;
    text-align: right;
   }

                