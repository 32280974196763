
.container_datedenaissanceinput{
    position: relative;
    height: 65px;
    padding-top: 20px;

    .logocopy{
        position: absolute;
        top: 29px;
    }
}

.datenaissance_label{
    position:absolute; height: 14px;line-height: 14px;left: 0px;top:0px;
    z-index: 10;color: #344054;font-size: 14px;font-weight: 600; 
}

.datedenaissanceinput{
    background-color:  #FFF !important;
    padding-left: 10px;
    font-size: 16px;
    height: 40px;
    outline: none;
    border: 1px solid #cfd4dc;
    width: 190px;
    border-radius: 8px;
    &:focus{
        border: 1px solid #ff8001;
    }
    &.datevalid{
        background-image: url(../../images/images_infosassures/checkcircle.svg);
        background-position: right 10px center; 
        background-repeat: no-repeat
    }
    
    &.datenonvalid{
        background-image: url(../../images/images_infosassures/errorcircle.svg);
        background-position: right 10px center; 
        background-repeat: no-repeat
    }
}

