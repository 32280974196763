
.tableads{
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
    width: 835px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    margin-bottom: 25px;
    &_titre{
      display: flex;
      align-items: center;
      justify-content: space-between;      
        height: 92px;
        font-size: 18px;
        font-weight: 500;
        line-height: 68px;
       
        border-bottom: 1px solid  #EAECF0;
        padding-left: 24px;
        padding-right: 24px;
      .texte{ color: #101828;}
    }
}


.demarrersequence{color:  #FFF;font-size: 14px;font-weight: 500;padding: 0px 14px 0px 44px;border-radius: 8px; height: 52px;line-height: 52px;
                background-color:  #62A2FF;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);cursor: pointer;
                background-position: 14px center;  background-repeat: no-repeat; background-size: 20px ;
                 background-image: url(../../images/sequence.svg);
   }
.trpositionabsolute{position:relative ;}
.divpositionabsolute{
  position: absolute;  font-size: 10px;  color: red;  font-weight: bold;
  left: 10px; bottom: -3px;
}

.containertablo{
    height: calc(100vh - 475px);
    overflow-y: scroll;
    &_search{ height:calc(100vh - 345px);}
    &::-webkit-scrollbar {   display: none;}

}

#searchtable{

  .containertablo{ height: calc(80vh - 180px);}

}

.tablecasenomtel{
    width: 220px;
    height: 54px;
    overflow: hidden;
    span{display: block; height: 27px; white-space:nowrap;   }
    // position: none;
}
.nomcampagne{
  color:  gray;
  font-size: 9px;
  position: absolute;
  bottom:5px;
  left: 50%;
  transform: translateX(-50%);

}
.nbjours{
  text-align: center; font-size:16px; font-weight: bold;
}
.casestatut{
        display: inline-block;
        width: 145px;
        height: 54px;
        overflow: hidden;
        white-space:nowrap; 
        height: 54px;
        line-height: 54px;
        font-weight: 500;
        color: gray;
        font-size: 14px;
}
.minibadge{
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  background-color: #FF8001 ;
  color: #FFF;
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  border-radius: 8px;
  margin-right: 6px;
  &.minibadgeechec{background-color: red;}
  &.minibadgegagne{background-color: green;}
}

table{
    border-collapse: collapse ;
    table-layout: fixed;
}

.toptablo{
    background-color: #F2F4F7;
    color: gray;
    color: #667085;
    font-weight: 400;
    display: flex;
    font-size: 12px;
width: 100%;
    font-weight: 400;
    div{height: 48px;  line-height: 48px;}

   

}
.col0{  width: 70px;}
.col1{   width: 207px;}
.col2{ width: 250px;}
.col3{width: 70px;text-align: center;  }
.col4{ width: 185px;}
.col5{ width: 50px;}
.pl0{padding-left: 0;}
.pl10{padding-left: 10px;}
.tbodytableads tr{
    // &:nth-child(even){background-color: #FFF;}
    // &:nth-child(odd){background-color: var(--gray-cool--50);}
    // border-bottom: 1px solid #0068FF;
    cursor: pointer;
    color: var(--gray500);
    font-weight: 400;
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-m);
    height: 72px; 
    border-bottom: 1px solid #EAECF0;
    // text-align: center;
    // &:hover{
    //     opacity: 0.6;
    // }
  .caseimgactions{
    line-height:70px;
    position: relative;
    img{
        display: inline-block;
        margin-left: 0px;
        cursor: pointer;
        vertical-align: middle;
    }

  }

}



.countappels{
  position: absolute;
  top:24px;
  right: 4px;
  width: 20px ;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #3084FF;
  color : #FFF ;    
  text-align: center;
  &.small{
    font-size:9px;
  }
  &.medium{
    font-size: 11px;
  }
  &.big{
    font-size: 12px;
  }
}
.divflex{  border: 1px solid red;
//   display: flex;
//  justify-content: center;
}

.tbodytableads{
    td {height: 54px; }
    td:not(.last_tr){padding: 0 10px;}
}
.caseimg{
    text-align: center;
    position: relative;
    z-index: 0;
  img{border-radius: 50%;}  
  &.secu{
    &::after{content:'SECU';position: absolute; z-index: 10; font-size: 10px ;height: 14px;line-height: 14px; right: 20px;bottom: 11px;color: #0068FF; border: 1px solid #0068FF; padding: 0 4px;    border-radius: 7px; }
  }
  &.tns{
    &::after{content:'TNS';position: absolute; z-index: 10; font-size: 10px ;height: 14px;line-height: 14px; right: 20px;bottom: 11px;color: #0068FF; border: 1px solid #0068FF; padding: 0 4px;    border-radius: 7px; }
  }
  &.senior{
    &::after{content:'SENIOR';position: absolute; z-index: 10; font-size: 10px ;height: 14px;line-height: 14px; right: 20px;bottom: 11px;color: #0068FF; border: 1px solid #0068FF; padding: 0 4px;    border-radius: 7px; }
  }
 
}


.alert{
  cursor: none;
  pointer-events: none;
  width:50px;
  height:50px;
  background-position:center center;  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-image: url(../../images/alert.svg);

}


.flamme{
  cursor: pointer;
  width:50px;
  height: 80px;
  padding: 0 10px;
  background-position:center center;  background-repeat: no-repeat;
  background-size: 30px 30px;
  &.cold{
      background-image: url(../../images/flamgrise.svg);
  }
  &.hot{
      background-image: url(../../images/flammehot.svg);
  }
}
