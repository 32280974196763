.mailfavori{
    position: absolute;
    right: 5px;
    bottom:-20px;
    z-index: 100;
   svg{    pointer-events: none;}
}

.selectfavori{
    cursor: pointer;
    display: inline-block;
     width: 30px;
     height: 30px ;
     line-height: 30px ;

    z-index: 100;
   svg{    pointer-events: none;}
}

.flexinpurcoeur{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    }
.svgmail{

    cursor: pointer;
    &.selected{  fill:#ff0000;}
    &.noselected{fill: #FFF; }
    
}