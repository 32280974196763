.containerjauge{
    height:35px ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}


.containtimagenivos {text-align: center;
    width:22px;
    svg{pointer-events: none;      width: 25px;   display: inline;margin:auto; vertical-align: bottom;
        @media screen and (max-width: 600px) {width: 24px;margin-top:10px   }
    }


    &_nivo{
        width: 16px;

        height:6px;
        border-radius: 0px;
        margin-top: 1px;
        background-color: #E0E0E0;
 
        &.bleu{
            background-color:  #3084FF ;
        }
    }
   }
