.sidebar{
    position: fixed;
    left: 0;
    right: 0;
    width: var(--sidebar-width);
    height: 100vh;background-color: #FFF;
      text-align: center;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right:1px solid #F9FBFB    ;
    z-index: 1000 ; 
    .containtel{margin: auto;}
}
#startdemo2{cursor: pointer;
  width: 5px;
  height: 5px;
  opacity : 1 ;
  border: 1px solid red;  
}

.visite{
  display: inline-block;
  cursor: pointer;
  margin-top: 8px;
}

.logotop{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 30px;
    margin: 20px auto ;

}

.avatardivider{
    width:  70% ; height: 1px;  background: #EAECF0; margin: 15px  auto; 
}

.avatar{
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
}



@keyframes clignoter {
    0%   { opacity:1; }
    40%   {opacity:0; }
    100% { opacity:1; }
  }
.displayno{
border: none;
}

.displayyes{
  border: 3px solid red;
}

.avatar2{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: lightgrey;
  color: #FFF;
  line-height: 60px;
  font-size: 30px ;
  font-weight:500;
  text-align: center;
  margin: auto;
}


.iconsidebar{

  position: relative;
  width: 48px;
  height: 48px;
  margin: auto;
  cursor: pointer;
  border-radius: 6px;
  background-color: #FFF;
  background-position: center center;  background-repeat: no-repeat; background-size: 20px ;
  &.ecrangestionleads{   background-image: url(../images/icongestion1.svg); &.selected{ background-color:#DCEAFF;}  }
  &.ecrangestion{   background-image: url(../images/icongestion1.svg); &.selected{ background-color:#DCEAFF;}  }
  &.ecrangestionleadsnew{   background-image: url(../images/icongestion1.svg); &.selected{ background-color:#DCEAFF;}  }

  &.params{   background-image: url(../images/iconparams.svg); &.selected{ background-color:#F9FAFB;}  }
  &.dev{   background-image: url(../images/icondev.svg); &.selected{ background-color:#F9FAFB;}  }
  &.yoan{   background-image: url(../images/icondev.svg); &.selected{ background-color:#F9FAFB;}  }
  &.dev2{   background-image: url(../images/icondev.svg); &.selected{ background-color:#F9FAFB;}  }
  &.notifsbell{   background-image: url(../images/iconalertes.svg); &.selected{ background-color:#F9FAFB;}  }
  &.manager{   background-image: url(../images/iconmanager.svg); &.selected{ background-color:#F9FAFB;}  }
  &.leadsmonth{   background-image: url(../images/iconleadsmonth.svg); &.selected{ background-color:#F9FAFB;}  } 
  &.dashboard{   background-image: url(../images/iconleads.svg); &.selected{ background-color:#F9FAFB;}  } 
  &.ecrantickets{   background-image: url(../images/iconecrantickets.svg); &.selected{ background-color:#F9FAFB;}  } 
  &.ecranemails{   background-image: url(../images/iconecranemail.svg); &.selected{ background-color:#F9FAFB;}  } 
  &.ecranemailsgestion{   background-image: url(../images/iconecranmailsgestion.svg); &.selected{ background-color:#F9FAFB;}  } 
  &.ecransuperadmin{   background-image: url(../images/iconecransuperadmin.svg); &.selected{ background-color:#F9FAFB;}  } 


}
.cursorpointer{cursor:pointer}
.displaycalendar{ 
  position: relative;
  width: 48px;
  height: 48px;
  margin: auto;
  cursor: pointer;
  border-radius: 6px;
  background-image: url(../images/btncalendar.svg); }
.nbtickets{
  position: absolute;
  top:4px;
  right: 4px;
  width: 20px ;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #3084FF;
  color : #FFF ;    
  text-align: center;
  &.small{
    font-size:9px;
  }
  &.medium{
    font-size: 11px;
  }
  &.big{
    font-size: 12px;
  }
}