.comgestion_docs_input{
  width: 100%;
  height: 100%;
  opacity: 0;
}

.comgestion{

        position: relative;
        top: 10vh;
        background-color:#FFF ;  
        width: 600px;
       height: auto;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
       border-radius: 12px;overflow: hidden;  margin: auto;
        padding: 70px 15px 20px 15px ;
        background-position:center 15px;  background-repeat: no-repeat;
        background-size: 40px 40px;
        background-image: url(../../images/icon_comgestion.svg);
        //icon_comgestion

}
.pbot{
  padding-bottom: 70px;
}
.containtlistefilecomgestion{
    position : absolute  ; bottom: 60px ; 
    display: flex; flex-wrap: wrap; width:300px ; gap:10px ;
    margin:8px auto;
}


.comgestion_titre{
    color:  #101828;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
}
.comgestion_soustitre{
    color:  #667085;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-bottom: 25px ;
}

.comgestion_btngrp{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:15px ;

}

.comgestion_buttons {  position: absolute;  bottom:15px ;   display: flex; gap: 20px;  width: calc(100% - 30px)  }
.alerterror{position: absolute;  top   :-180px ;  font-size: 12px; font-weight: bold; color:red ; text-align: center;  width: calc(100% - 30px) }
.comgestion_btn{
    width:277px; color:#FFF ; background-color:  #ff8001; border-radius: 8px; cursor: pointer;  
    height: 40px;line-height: 40px; text-align: center;  font-size:16px;

} 

// .containerlistecompagnies{position:relative}
.comgestion_listecompagnies{position:absolute;background-color:#FFF; z-index:2000;top:69px; left:0px;
                             width:270px; max-height:400px; overflow-y:scroll ; &::-webkit-scrollbar {   display: none;} 
                            div{cursor: pointer;}}

.comgestion_fin_line{ width: 80%;  text-align: center; margin:10px auto;}
 

.comgestion_docs{    border: 1px solid #EAECF0; 
                    border-radius: 8px;
                    position: relative;
                    width: 300px;  height: 200px;
                    margin:40px auto  10px auto;
                    background-position: center  ;  background-repeat: no-repeat;
                    &.noupload{background-image: url(../../images/uploadwait.svg);}
                    &.upload{background-image: url(../../images/upload.svg);}
                    input{color:transparent}
}


.comgestion_messagetitre{ width: 350px;  height: 40px;background: #FFFFFF;border: 1px solid #EAECF0;
    border-radius: 8px;   margin: 5px  auto; padding-left:10px ;}

.comgestion_messagecontent{width: 350px;  background: #FFFFFF;border: 1px solid #EAECF0;border-radius: 8px;  
    height:150px;color:  #667085;padding: 20px;outline: none;   margin: 5px  auto;  resize: none;}

.comgestion_docsobligatoire{
    position: absolute; top: 118px; color: red; font-size: 10px;  text-align: center; width: 100%;
}


.loadingwait{
    position: absolute;
    top: -200px ;
    margin-left: auto;
margin-right: auto;
left: 0;
right: 0;

    width: 50px;
    height: 50px;
    cursor: none;
    margin: auto;
    pointer-events: none;
    opacity: 0.3;
      background: url("../../images/loadingorange.svg") no-repeat center/cover;
      animation: rotate 4s infinite linear;

  }
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
@-webkit-keyframes rotate {
    from {-webkit-transform:rotate(0deg);}
    to {  -webkit-transform:rotate(360deg);}
  }
  
  @-moz-keyframes rotate {
    from {-moz-transform:rotate(0deg);}
    to {  -moz-transform:rotate(360deg);}
  }

