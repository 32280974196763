.famille{  background-color:#FFF; z-index: 1; overflow-y: scroll ;&::-webkit-scrollbar {display: none; } ; height:calc(100vh - 100px)  ; 
        padding: 10px; border-radius: 10px; }

.titre{display: flex;justify-content: space-between;}

.titre_left{ font-weight: 500; font-size: 18px;color: #101828;}

.titre_right{font-weight: 400; font-size: 14px;  color: #98A2B3;}


    .infosfamille_line{
        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        line-height: 30px;
        &_label{font-size: 13px;  width: 130px;white-space: nowrap;}
    }
    .champdata{
        display: inline-block; height: 25px; line-height: 25px;width: 300px; overflow-x: hidden;
        background-color: #EAECF0;
        margin-left: 5px;
        border: none;
        white-space: nowrap; 
        overflow-y:  hidden;
        &:focus{ outline: none;  }
        br{display: none;}
    }
    .btnenregistrer{
        color: #FFF;
        background-color: green;
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
       cursor: pointer;
        border: none;
        outline : none ; 
    
    }
    .logocopygestion{
        cursor: pointer;
         margin-left: 5px;
        width: 16px;
        height: 17px;
        background-image: url(../../images/copy.svg);
        background-size: cover;
        background-position:center;
        background-repeat: no-repeat;
    }