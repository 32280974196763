.contratwin_box2{
    border: 1px solid  #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
    display: flex; flex-wrap: wrap; justify-content:space-between


}



.contratwindevis_upload2{
    width: 300px;
    position: relative;
    height: 130px;
    border: 1px solid  #D0D5DD;
    border-radius: 8px;
    background-color: #FFF;
    background-image: url(../../images/uploadwait.svg);
    background-repeat: no-repeat;
    background-position:center 20px ;  
    padding: 16px 10px   ;
    margin:15px auto ;
    &_texte{
        width: 100%;
        height: 110px;
        padding-top: 80px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #E47200;
    }
}
