.zindexbig{z-index:2000}

.cardchangeemail{   position: relative; top:25vh  ;  border-radius: 12px; padding: 20px  16px;  width: 450px; background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    margin: 50px auto; }

    .cardchangeemail_titre{color:#101828;text-align: center;font-size: 18px;
                        font-weight: 500; line-height: 28px;  height: 28px;margin-bottom: 30px; }

.cardchangeemail_btnorange{border-radius: 8px;border: 1px solid  #FF8001;background:   #FF8001;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); height: 44px;  
            color: #FFF;font-size: 16px;font-weight: 500;line-height: 44px;
            text-align: center;  margin-bottom: 10px; cursor: pointer;
}

.cardchangeemail_btnblanc{
    border-radius: 8px;border: 1px solid  #D0D5DD;background:   #FFF;
    box-shadow:  0px 1px 2px 0px rgba(16, 24, 40, 0.05); height: 44px;  
    color: #344054;font-size: 16px;font-weight: 500;line-height: 44px;
    text-align: center; cursor: pointer;
}

.cardchangeemail_soustitre{color:  #344054;font-size: 14px; font-weight: 500;line-height: 20px;
                         height: 20px; margin-bottom: 3px;}


.cardchangeemail_input{  border-radius: 8px;border: 1px solid  #D0D5DD;background:   #FFF;
    box-shadow:  0px 1px 2px 0px rgba(16, 24, 40, 0.05); height: 44px;  
    color: #344054;font-size: 16px;font-weight: 500;line-height: 44px;  width: 100%; padding: 0 10px; margin-bottom: 15px; }


.boutondisable{
    pointer-events: none; opacity: 0.5;
}