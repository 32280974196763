
.cardcontrat{
    position:relative;
    cursor: pointer;
    min-width: 564px;
    height: 112px;
    display:flex ; 
    justify-content: space-between;
    align-items: center;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    margin-bottom: 15px;
    z-index:1;
    padding:16px 0px 16px 16px ;
    &_attentesignature{
        opacity: 0.4;
    }
    &.noaction{
        cursor: none;
        pointer-events: none;
    }

}
.pasdelogo{width: 70px; height: 70px; border-radius: 50%; background-color: #667085; display: flex; justify-content: center;  align-items: center;
    div{line-height: 11px;  color: lightgray; font-size: 10px;font-weight: bold; text-align: center; } }

.indiccontrat{position: absolute; left: 8px; bottom:0 ;  font-size: 11px;  color:red;font-style: italic;font-weight: bold;}
.deletecontrat{position: absolute; right: 8px; bottom:0 ;  font-size: 11px;  color:red;font-style: italic;font-weight: bold; cursor: 0;  z-index: 2000; }
.cardcontrat_image{img{display: inline-block; border-radius: 50%; }}
.cardcontrat_formule{ 
    display: flex; flex-direction: column;align-items: flex-start;width: 167px;  padding-left: 10px;
    &_line1{color:#101828;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 500;line-height: 20px;}
    &_line2{color:  #667085;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 400;line-height: 20px; }

}
.cardcontrat_details{
    display: flex; flex-direction: column;align-items: flex-start;width:229px; 
    &_numcontrat{color: #101828;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 700;line-height: 16px;}
    &_titulaire{color: #101828;font-family: Poppins;font-size: 12px;font-style: normal;font-weight: bold;line-height: 16px;}
    &_dates{ height: 20px;
         .graybold{color:  #667085;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 700;line-height: 16px;}
         .lightgray{ color:  #667085;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 400;line-height: 16px; }
    }
}

.cardcontrat_prix{  width : 120px ; 
    display: flex;height: 112px;padding: 16px 5px;flex-direction: column;justify-content: center;align-items: center;
    &_line1{color: #101828;font-family: Poppins;font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;}
    &_line2{color: #101828; font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 400;line-height: 20px; }
}
                 