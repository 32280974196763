.contratwin{
    margin: auto;
    margin-top: 150px;
    width: 1300px;
    height: 618px;
    border-radius: 12px;
    background:#FFF;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
 
    &_content{
        display: flex;
    }
  .underline{text-decoration-line: line-through;}
    .contratwinclose{ position: absolute;right: 10px; top : 10px;  cursor: pointer;}

    &_top{
        position: relative;
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        align-items: center;
        height: 68px;
        padding-left: 16px;
        border-bottom: 1px solid   #D0D5DD; 
        &_texte{
            color:  #101828 ;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 68px; 
        }
        &_avancement{
                width: 240px;
                height: 8px;
                background: #ECF4FF;
        }
    }
    &_left{
        width: 380px;
       padding: 20px;
       &_titre{
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #101828;
       }
      
    }
    &_right{
        position: relative;
        width: 920px;
         height: 550px;
        padding : 22px; 
        overflow-y: scroll;
        border-left: 1px solid  #D0D5DD;
        &::-webkit-scrollbar {   display: none;}
    }

    &_divchoice{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 44px;
        color:#667085;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding: 0 12px;
        border-bottom: 1px solid  #D0D5DD;
        &.choiced{
            background-color:  #F2F4F7;
        }
        &_texte{font-size: 14px;
            font-style: normal;
            font-weight: 500;
            height: 44px;
            line-height: 44px; 
            color:#667085;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            pointer-events: none;
        }
        &_valid{
            pointer-events: none;
            width: 20px ;
            height: 20px;
            background-position: center center;  background-repeat: no-repeat;
            background-size: 20px 20px; 
            &.yes{background-image: url(../../images/valideetapeyes.svg);}
            &.no{background-image: url(../../images/valideetapeno.svg);}
        }
        &_optionnel{
            pointer-events: none;
            background: #FFEAD6;
            color: #E47200;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
        }


    }
}
.alertwinred{
    h1 ,li{color: red ; }
    ul{padding-left: 25px       ;}
    
}
.contratwin_box{
    border: 1px solid  #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;

    &_flex{
        width: 100%;  display: flex; flex-wrap: wrap; justify-content:space-between
    }
}



.contratwin_box_top{
    padding: 15px 20px;
    border-bottom: 1px solid  #D0D5DD;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}


.contratwin_box_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    height: 79px;
    background-color: #FFF;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &_containerboutons{display: flex; gap: 10px; }
}
.contratwin_box_bottom_left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 79px;
    gap: 15px;
}


.contratwin_box_bottom_message{
    padding-right: 75px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085;
    background-position: right center  ;
    background-repeat: no-repeat;
    background-size:70px 20px ;
    background-image: url(../../images/yousigngris.svg);
}