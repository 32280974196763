
.aftercall{
    // position: relative;
    // margin:50px auto  ;
    // display: flex;
    // max-width: 650px;
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    // display: inline-block;
    margin-left:  auto ;
    margin-right:  auto ;
    margin-top: 100px;
    div{text-align: center;}
    height: 210px;
   width: 485px;

    padding: 20px 16px 16px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;

    
}

.aftercall_titre1{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    height: 28px;
    text-align: center;
    color: #101828;
}

.aftercall_titre2{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #667085;
    margin-bottom: 10px;
}
