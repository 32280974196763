
.nbdevis{
    color: #FFF;
    background-color: #3084FF;
    height: 22px;
    line-height: 22px;
    text-align: center;
    width: 22px;
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 600;
    vertical-align: middle;
    border-radius: 50%;
}

.positionabsolute{position: absolute;}
.visible{visibility: visible;}
.nonvisible{  visibility: hidden;  }

.paddingtop{
    padding-top: 70px;
}

.width100{width: 100%;}
.ficheclient_containerformules{
    padding: 0px 15px 10px 15px;
}

.ficheclient_bottom{
&_left , &_right{
    background: #FFFFFF;
    border-radius: 8px ;
    border: 1px solid  #EAECF0; 
}
&_left{
    width: var(--fiche-client);
    position: fixed;
   left: calc(var(--sidebar-width) + 32px);
    top:170px;
    // height: calc(100vh - 180px);
    overflow-y: scroll ;
&::-webkit-scrollbar {
        display: none;
      }
    &_top{z-index: 10;
        position: fixed;
        width: inherit;
        height: 46px;
        background-color: #EAECF0;
        padding: 3px;
       
        display: flex;
        justify-content: flex-start;
        border-radius: 8px 8px 0px 0px;
    }
}
&_right{
    position: fixed;
    right: 35px;
    top:170px;
    width: var(--client-right); 
    height: calc(100vh - 180px);
    overflow: hidden;
    &_content{
        position: relative;
        padding-top: 60px ;
        height: calc(100vh - 185px);
    }
    &_top{position: fixed;
        width: inherit;
        height: 46px;
        background-color: #EAECF0;
        padding: 3px;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        border-radius: 8px 8px 0px 0px;
    }   
}
}

.case{
    cursor: pointer;
    text-align: center;
    width: 150px;
    height: 36px;
    line-height: 36px;
     background-color: transparent;
     box-shadow: none;
     border-radius: none;
     color: #667085;
     font-size: 14px;
    &.selected{
        color: #344054;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 6px;
    }
    
}


.contentupload{
    height: auto;
    max-height: calc(100vh - 180px);
    padding: 60px  15px 10px 15px;
     width: 100%;  display: flex; flex-wrap: wrap; gap: 18px;
     overflow-y: scroll ;
     &::-webkit-scrollbar {  display: none;}
}