.notifs{
    position: fixed;
    bottom: 70px;
    left : 70px;
    width: 360px;
    background-color: #FFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    z-index: 1000;

}

.notif{cursor: pointer;padding: 15px 20px  15px 65px;  position: relative;
    background-position: 10px center;background-repeat:no-repeat ; background-size: 40px 40px;
    &_enterlead{background-image:url(../../images/history/iconenterlead.svg)}
    &_qualiflead{background-image:url(../../images/history/iconqualifylead.svg)}
    &_affectationticket{background-image:url(../../images/history/iconqualifylead.svg)}
    &_email{background-image:url(../../images/history/iconemail.svg)} 
    &_appelentrant{background-image:url(../../images/history/iconappelentrant.svg)}
    &_signature{background-image:url(../../images/history/iconsignature.svg)}
    // &_enterlead{background-image:url(../../images/history/iconappelentrant.svg)}

}

.delnotif{position:absolute ; right: 15px; top: 50%  ;  width: 20px;  height: 20px; 
    background-image: url(../../images/delnotif.svg);  
    background-position: center center;  background-repeat: no-repeat; background-size: 8px ;
}



.notif{div:not(.delnotif){pointer-events: none;}}

.notif:not(:last-child){ border-bottom: 1px solid  #EAECF0;}

.notif_noopened{background-color:#E3E7EE;}

.notif_date{
    pointer-events: none; 
    height: 18px;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #FF9123;
}

.notif_texte{
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    font-weight: 400;
    width: 208px;
    span{color:#FF9123;font-weight: 700;}
}

.notif_popup{
    padding: 15px 20px;
    border-radius: 12px ; 
    background-color: #FFF;
    position: absolute;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    position: fixed;
    bottom: 70px;
    left : 70px;
    width: 360px;
    z-index: 1000;

}

.notifs_top{
    height: 80px ;
    line-height: 80px;
    justify-content: space-around;
    align-items: center;
    background-color: #FFF;
    border-radius: 12px 12px 0 0 ;
    padding-left: 40px;
    background-position: 10px center;background-repeat:no-repeat ; background-size: 20px ;
    background-image:url(../../images/clocheorange.svg) ;
    color: #FF8001 ;
    font-weight: 500;
    border-bottom: 1px solid  #EAECF0;
    span{ display: inline-block; font-size: 12px; color: #027A48 ; background-color: #ECFDF3;
             height: 22px;  line-height: 22px;  border-radius: 10px; text-align: center; margin-left: 12px; }
}


.notifs_bottom{
    display: flex;
    height: 80px ;
    justify-content: space-around;
    align-items: center;
    background-color: #FFF;
    border-radius:  0 0  12px 12px;
    border-top: 1px solid  #EAECF0;
}

.notifs_middle{
    width: 360px;
    max-height: 350px;
    overflow-y: scroll;
    &::-webkit-scrollbar { display: none; } 
}