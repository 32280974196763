.thead{
    background-color: #F2F4F7;
    color: gray;
    color: #667085;
    font-weight: 400;
    font-size: 12px;
    font-weight: 400;
    td{height:26px;  line-height: 26px;  }

}

.nomcompintab{
    text-align: center;
    padding: 4px 0;
    font-size: 20px;
    font-weight: 700;

}