
.listeformules{
    &.visible{visibility: visible;}
    &.nonvisible{ visibility: hidden;}


    &.client{height: calc(100vh - 330px); }
    &.ficheclient{height: calc(100vh - 320px); padding-bottom: 200px; }
    &.souscription{ height: 400px;}
     overflow-y: scroll ;
     &::-webkit-scrollbar {  display: none;}
 }
 