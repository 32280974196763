@import '../EcranParametres.scss' ;


.tablogammes_tablo{
    width: 1100px;
    &_line1{background-color: #F9FAFB;height:52px ; display: flex;border-top-right-radius : 8px ; border-top-left-radius : 8px ; 
                div{line-height:52px ; font-weight: 500;font-size: 12px; color: #667085;}
                & > div:nth-child(1){padding-left: 15px;  width :170px ;}
                & > div:nth-child(2){width :250px; }
                & > div:nth-child(3){width :130px ;}
                & > div:nth-child(4){width :200px ;}
                & > div:nth-child(5){width :84px ;text-align : center }
                & > div:nth-child(6){width :84px ;text-align : center }
                & > div:nth-child(7){width :84px;text-align : center }
                & > div:nth-child(8){width :84px ;text-align : center }
    }    
    &_line{background-color: #FFF;height:52px ; display: flex;border-top-right-radius : 8px ; border-top-left-radius : 8px ; 
       div{line-height:52px ; font-weight: 500;font-size: 12px; color: #667085;}
                & > div:nth-child(1){padding-left: 15px; width :170px ;}
                & > div:nth-child(2){width :250px; }
                & > div:nth-child(3){width :130px ;}
                & > div:nth-child(4){width :200px ;}
                & > div:nth-child(5){width :84px ;line-height: 52px;}
                & > div:nth-child(6){width :84px ;}
                & > div:nth-child(7){width :84px;}
                & > div:nth-child(8){width :84px ;}
   }    
   &_lines{max-height:calc(100vh - 300px); }
}


.tablogammes_tablo_input{
   width: 150px;
   height: 44px;
   padding: 10px 14px 10px 14px;
   gap: 8px;
   border-radius: 8px;
   // opacity: 0px;
   border: 1px solid #D0D5DD;
   box-shadow: 0px 1px 2px 0px #1018280D !important;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   text-align: left;
   color: #667085;
}