.popup{
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    align-items: center;
    padding: 22px 16px;
    gap: 23px;
    width: 360px;
    height: 136px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    z-index: 6000;
    &.yes{
        display: flex;
    }
    &.no{
        display: none;
    }
    img{
        display: block;
        width: 48px;
        height: 48px;
    }
}
.popup_texte{
    font-size: 18px;
line-height: 28px;
text-align: center;
color: #101828;

}
