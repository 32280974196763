.dashbordtop_top , .dashbordtop_right {
    display: flex;
    justify-content: space-between;
    
}

.dashbordtop_top{
    margin-bottom: 10px;
}
.topnumtel{
    height: 18px; line-height: 18px; color: grey;  font-weight: 200;   font-size: 15px ;   margin-bottom: 10px;
}
.dashbordtop {
    padding-top: 25px;
    h1{
        font-size: 30px;
        font-weight: 500;
        color: #101828;
    }
    p{  height: 32px; line-height: 16px; 
        font-size: 16px;
        font-weight: 400;
        color: #667085;
    }
}


.dashbordtop_input{ padding-left: 36px;
                    margin-right: 15px;
                    height: 40px;
                    line-height: 40px;
                    width: 230px;
                    border: 1px solid #D0D5DD;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;
                    outline: none;
                    background-position: 10px 50%;  
                    background-size: 20px;
                    background-image: url("../../images/search.svg");
                    background-repeat: no-repeat;
}