
.phone{
    width: 44px;
    height: 44px;
    cursor: pointer;
    background-position: center center;  background-repeat: no-repeat; background-size: 44px;
    background-image: url(../../images/telvert.svg); 
    &.telvert{background-image: url(../../images/telvert.svg)}
    &.telrouge{display: inline-block;   background-image: url(../../images/telrouge.svg)}
}
    

.telanimation{
    background-image: url(../../images/telrouge.svg);
    animation: animtel  4s infinite;
  }
  
@keyframes animtel {
    0%   {opacity: 0.5; transform:  scale(1);}
    50%   {opacity: 1; transform:  scale(1.4);}
    100%  {opacity: 0.5; transform:  scale(1);}
  }
  
  /* The element to apply the animation to */
