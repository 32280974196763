

.cardformulejauge{
position: relative;

    display: flex;
    gap : 7px;
    width: 225px;
    padding-top: 20px;
    padding-bottom: 10px;

}

.ht6{height: 6px;}

.containtimagenivos {
    position: relative;
    text-align: center;
    width:22px;
    height: 75px;
    padding-top: 10px;
    // display: flex;
    svg{pointer-events: none;      width: 25px;   display: inline;margin:auto; vertical-align: bottom;
        @media screen and (max-width: 600px) {width: 24px;margin-top:10px   }
    }


    &_nivo{
        width: 16px;

        height:6px;
        border-radius: 0px;
        margin-top: 1px;
        background-color: #E0E0E0;
 
        &.bleu{
            background-color:  #3084FF ;
        }
    }
   }



.itemsbottom{
    align-items: bottom;
}

.poppinsbold{
    font-family: Poppins-bold;
}


.imageposte{
    background-position: center  bottom;
    background-repeat: no-repeat;
    background-size:23px 23px ;

    &.hospi{ background-image: url(../../images/images_medic/hospi.svg)}
    &.consults_specialistes{ background-image: url(../../images/images_medic/consults_specialistes.svg)}
    &.optique_lunettes{ background-image: url(../../images/images_medic/optique_lunettes.svg)}
    &.optique_lentilles{ background-image: url(../../images/images_medic/optique_lentilles.svg)}
    &.dentaire_prothese{ background-image: url(../../images/images_medic/dentaire_prothese.svg)}
    &.dentaire_implant{ background-image: url(../../images/images_medic/dentaire_implant.svg)}
    &.dentaire_orthodontie{ background-image: url(../../images/images_medic/dentaire_orthodontie.svg)}
    &.prevention{ background-image: url(../../images/images_medic/prevention.svg)}


}





