input:-webkit-autofill{
    -webkit-text-fill-color:  #667085 !important;
}

.loginpage{


width: 100%;
height: 100vh;
display: grid;
grid-template-columns: 1fr 1fr;
    &_left{
        display: flex;
        flex-direction: column;
        padding-left: 150px;
        justify-content: center;
        align-items: left;  
        &_container{
           position: absolute;
           top: 50%;
           left:25% ;
           transform: translate(-50%,-50%);
             width: 360px;
        }
    }
    &_leftlogo{margin-bottom: 50px;}
    &_lefthello{margin-bottom: 15px;font-weight: 600;font-size: 30px;line-height: 38px;color: #101828;}
    &_lefttxt{margin-bottom: 25px;font-weight: 400; font-size: 16px;line-height: 24px;color: #667085;}
    &_right{ background-position:center center;  background-repeat: no-repeat;  
              background-size: cover;background-image: url(../images/loginphoto2.svg);}
}
.errormdp{  position: absolute; bottom: -15px;  font-size: 11px; font-weight: bold; color : red}
.loginfo{
    color: red;
    margin-top: 15px;
    font-size: 12px;
    span{
        font-weight: bold;
        cursor: pointer;
    }
}


.loginbutton{
    display:flex;
    align-items: center;
    justify-content: center;
    width: 360px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer; 

    &_img{
        width: 24px;
        height: 24px;
        background-position:center center;  background-repeat: no-repeat;
        background-size: cover;
     background-image: url(../images/icongooglecalendar.svg);
     margin-right: 5px;
    }
    &_txt{
        font-weight: 500;
        margin-left: 5px;
        font-size: 16px;
        line-height: 24px;
        height: 24px;
        color:  #344054;
        ;
    }

}

.loginseparate{display: flex; align-items: center;justify-content: space-around; font-weight: 400;font-size: 14px;
                line-height: 20px; text-align: center;color: #667085;  margin: 25px 0;
                span{display: inline-block ;}}

.linethrough{  width   : 45%  ; height: 1px ;  background-color:  #EAECF0;  }

.loginpage_titleinput{height: 20px; font-family: 'Poppins';font-style: normal; font-weight: 500;font-size: 14px;line-height: 20px;color: #344054; margin-bottom: 7px; }

.loginpage_input{font-family: 'Poppins';padding: 10px 14px; width: 360px; height: 44px;background: #FFFFFF;border: 1px solid #D0D5DD;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);border-radius: 8px;  margin-bottom: 20px;
                    font-weight: 400;font-size: 16px;line-height: 24px; color: #667085  !important;  }
