



.button{
    border-radius: 8px;
    border: 1px solid #ff8001;
    background-color: #FFF;
    color: #ff8001;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0 15px; 
    margin-bottom: 0px;
    cursor: pointer; 
    &_selected{
        color: #FFF;
        background-color: #ff8001;
    }
    &_disabled{
        pointer-events: none;
        cursor: none;
        
    }
    &_gender{
        height: 40px;
        line-height: 40px;
        &_selected{
            color: #FFF;
            background-color: #ff8001;
        }
    }
 
}


