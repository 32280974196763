.gestionleads{
    margin-top: 20px;
    width: 1200px;
    overflow: hidden;
    border: 1px solid #D0D5DD ;

    border-radius: 8px;
    &_top{display: flex;    height: 44px;
        div{font-size: 12px;  height: 44px;  line-height: 44px;font-weight: 500; text-align: left; color:  #667085; }
        & > div:nth-child(1){padding-left: 12px;padding-top: 5px;  width :32px ; }
        & > div:nth-child(2){padding-left: 15px;  width :187px ; }
        & > div:nth-child(3){padding-left: 16px;  width :176px ;  }
        & > div:nth-child(4){padding-left: 15px;  width :270px ; }
        & > div:nth-child(5){padding-left: 15px;  width :158px ; }
        & > div:nth-child(6){padding-left: 8px;  width :62px ;  }
        & > div:nth-child(7){padding-left: 15px;  width :163px ; }
        & > div:nth-child(8){padding-left: 15px;  width :120px ;  }
    }
    &_tablo{
        max-height: calc(100vh - 490px);
        overflow-y: scroll;
        &::-webkit-scrollbar {   display: none;}
        &_line:not(:last-child){ border-bottom: 1px solid #D0D5DD;}
 
    }
    &_tablo_line{display: flex; background-color: #FFF; 
       
        div{font-size: 12px;  height: 44px;  line-height: 44px;font-weight: 500; 
            text-align: left; color:  #667085; overflow: hidden;}
        & > div:nth-child(1){padding-left: 12px;padding-top: 5px; width :32px ; }
        & > div:nth-child(2){padding-left: 15px;  width :187px ; }
        & > div:nth-child(3){padding-left: 16px;  width :176px ;  }
        & > div:nth-child(4){padding-left: 15px;  width :270px ; }
        & > div:nth-child(5){padding-left: 15px;  width :158px ; }
        & > div:nth-child(6){padding-left: 15px;padding-top:6px  ;  width :62px ; img{border-radius: 50%;} }
        & > div:nth-child(7){padding-left: 15px;  width :163px ; }
        & > div:nth-child(8){padding-left: 15px;  width :120px ;  }
    }
    &_checkbox{
        width: 20px;
        height: 20px;
         border: 1px solid #D0D5DD;
        border-radius: 4px;
        cursor: pointer;
        &.checked{background-color:#D0D5DD; }
    }
    
}

.exportcsv{height: 40px;line-height: 40px;  padding:0px 10px 0px 40px ; display: inline-block;
            border-radius: 8px;border:  1px solid #D0D5DD;
            background-position: 10px center;  background-repeat: no-repeat;background-size: 18px 15px;
            background-image: url(../../images/exportcsv.svg); 
            cursor: pointer;font-size: 14px;font-weight: 500;
            color: #344054;
} 

.reassigner{ height: 40px;line-height: 40px;  padding:0px 10px 0px 40px ; display: inline-block;
    border-radius: 8px;border:  1px solid #D0D5DD;
    background-position: 10px center;  background-repeat: no-repeat;background-size: 18px 15px;
    background-image: url(../../images/reassigner.svg); 
    cursor: pointer;font-size: 14px;font-weight: 500;
    color: #344054;
} 

.grpbtnflex{
    margin-top: 10px;
    display: flex; gap:10px ; 
}