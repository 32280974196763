@import '../EcranParametres.scss' ;

.utilisateurmodifauths{
    width: 950px;
    // height: calc(100vh - 200px)  ;
    background-image: url(./../icon_modifauths.svg);
}

.equipeajoutmodif_titremin{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #667085;
}