.dashboardfiltres{margin-top: 25px   }
.containerdashbordtwocalendars{    z-index: 2000;
    position : absolute;
    top: 110px;
    left: 30px;}
.dashboardfiltres_top{  position : relative ; display: flex; width: 834px;  justify-content: space-between; margin-bottom: 5px;
                        &_left{display: flex;  gap: 12px;  }
                        &_btngrp{
                            display: flex;  gap: 8px;  max-width: 365px;
                        }
                        &_toggle{
                            background-image: url(../../images/iconplusfiltres.svg);background-position: left 10px center; 
                            background-repeat: no-repeat; background-size: 15px 10px;    cursor: pointer;
                            padding: 0px 12px 0px 33px;border-radius: 8px; border:  1px solid   #D0D5DD;background-color:  #FFF;
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);font-size: 14px;
                            height: 40px;  line-height: 40px ;color: #344054;  
                            div{display: inline-block; margin-left: 10px;  color:  #101828; font-size:16px;font-style: normal;font-weight: 600; }
                        }
                        &_filtre{position: relative;
                            background-image: url(../../images/iconfiltrescroix.svg);background-position: right 5px center; 
                            background-repeat: no-repeat; background-size: 15px;  padding: 0px 30px 0px 7px;
                            border-radius: 8px; border: 1px solid   #FFEAD6; background-color:  #FFEAD6;
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color:  #E47200; font-size: 12px;   height: 40px;  line-height: 40px ;        white-space: nowrap;

                            div{ position: absolute; right: 5px;top:10px ; cursor: pointer ;  height: 18px; width: 18px;   cursor: pointer;  }
                        }
                        &_filtredate{position: relative;padding: 0px 7px 0px 7px;
                            border-radius: 8px; border: 1px solid   #FFEAD6; background-color:  #FFEAD6;
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color:  #E47200; font-size: 12px;   height: 40px;  line-height: 40px ;        white-space: nowrap;  
                        }
                        &_displaycalendar{display: inline-block;
                            background-image: url(../../images/iconcalendar.svg);background-position: left 10px center; 
                            background-repeat: no-repeat; background-size: 30px 20px;    cursor: pointer;
                            padding: 0px 16px 0px 45px;border-radius: 8px; border: 1px solid  #D0D5DD;background-color:  #FFF;
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);font-size: 14px;
                            height: 40px;  line-height: 40px ;color: #344054; }
                }

    .dashboardfiltres_bottom{
          width: 834px;  z-index: 500;
        position: absolute;border-radius: 8px;border: 1px solid #D0D5DD;background:  #FFF;
        box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
        padding: 24px 32px  86px 32px ;
        &_listes{display: flex;  margin-top: 22px;}    
        &_liste{width: 400px;}
        &_titre{color:  #344054; font-size: 16px;font-style: normal;font-weight: 600; margin-bottom: 20px; }

        &_containtbtn {
            position: absolute;bottom: 0;background-color: #FFF;bottom: 0;left: 0;height: 60px; width: 832px;
            border-top: 1px solid  #EAECF0;border-radius:  0 0 8px 8px; text-align: right;padding: 10px 15px;
        }
    }


    .filtreselect{display: flex;  align-items: center; height: 22px;
                  &_check{ width: 16px;height: 16px;border-radius: 4px;border: 1px solid   #D0D5DD;background:  #FFF; margin-right:4px;
                          &.selectedcheck{background:#D0D5DD }}
                  &_texte{color:  #344054;font-family: Poppins; font-size: 14px;  }
    }

  