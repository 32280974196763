.alertepopupdelete{
    position: fixed;top: 250px;left : 50% ;transform: translateX(-50%);
    width: 408px;border-radius: 12px;background:#FFFFFF;
    padding: 92px 24px 24px 24px;
    background-image: url(../../../images/alertdelete.svg);
    background-position: center 24px;  background-repeat: no-repeat; background-size: 48px 48px ;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    & > div:nth-child(1){ height: 28px; font-weight: 500;font-size: 18px;line-height: 28px;text-align: center;color: #101828;  }
    & > div:nth-child(2){ height: 20px; font-weight: 400;font-size: 14px;line-height: 20px;text-align: center;color: #667085;}
    & > div:nth-child(3){ height: 20px; font-weight: 400;font-size: 14px;line-height: 20px;text-align: center;color: #667085;
                        span{  font-weight: 700;}}

     & > div:last-child{display: flex; justify-content: center; gap: 10px; margin-top: 20px;}
}