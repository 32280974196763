@import 'TitleInput.scss'; 
@import 'Button.scss'; 
@import 'Infos.scss'; 

.monformulaire{
    position: relative;
    &.souscription{ height: 510px; }
    &.ecranclient{  height: calc(80vh - 70px); }
}

.formulaire_content{
    overflow-y: scroll;
    overflow-x: hidden; 
    &::-webkit-scrollbar { display: none; }
    &.souscription{ height: 442px; }
    &.ecranclient{height: calc(80vh - 140px);padding: 0 10px; }
}





.formulaire_bottom{
    display: flex;   
    justify-content: space-between;
    align-items: center; 
    position: absolute;
    height:67px ;
    line-height: 67px;
    background-color: #FFF;
    padding: 0 15px;
    width: 100%;
    bottom: 0px;
    border-top: 1px solid  #EAECF0;
    border-radius:  0 0 8px 8px; 
    background-color: #FFF;
    z-index: 1000;
    &.souscription{
        border: 1px solid lightgray ;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    span{
        display: inline-block;
        margin-right: 10px;
        font-size: 14px;
        color: #344054;
        font-weight: 600;
    }
 }

 .btnnb{
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #98A2B3;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 12px;
    &.last{
        margin-right: 25px;  
    }
    &.select{
        color: #FFFFFF;
        background-color:#98A2B3;
    }
    &.noselect{
        background-color: #FFFFFF;
        color:#98A2B3;
    }
 }

