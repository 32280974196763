.formcourtier{
    overflow-y: scroll;   &::-webkit-scrollbar {   display: none;}
    height: calc(100vh - 260px); 
}



.coordonnes_titre{font-weight: 500;font-size: 18px;line-height: 28px;color: #101828;}
.coordonnes_soustitre{font-weight: 400; font-size: 14px; line-height: 20px; color: #667085;}
.paramsdivider{height: 1px;background: #EAECF0; margin: 15px 0;}
.coordonnes_section{display: flex;
                    border-bottom: 1px solid  #EAECF0;
                    padding: 15px 0  25px 0;
                    &_left{width: 300px;}
                    &_right{width: 512px; display: flex;  flex-wrap: wrap; 
                    &.flexright{display: flex;  justify-content: flex-end; padding-right: 5px;} }

                }


.paramslabel{width: 512px;font-weight: 500;font-size: 14px; height: 20px;line-height: 20px; margin-bottom: 6px ;  color: #344054;
    &.wd180{width:180px} 
    &.wd316{width:316px} 
    &.wd249{width:249px ; margin-right: 15px;} 
    &.wd248{width:248px} 
}

.paramsinput{background-color: #FFFFFF  !important;width:  512px;padding: 10px 14px;height: 44px;line-height: 24px;
                border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);border-radius: 8px;
                outline: none;  overflow-y: scroll; margin-bottom: 15px ;    &::-webkit-scrollbar { display: none;}
            &:focus{border: 1px solid #ff8001; box-shadow: none;      }
            &.ht68{height: 68px;}   
            &.wd180{width:180px ; margin-right: 16px;} 
            &.wd316{width:316px} 
            &.wd249{width:249px ; margin-right: 15px;} 
            &.wd248{width:248px} 
}

.nomdomaine{ display: inline-block ; width: 512px;   position: relative ; 
    &::after{content:'.mutuello.com';position: absolute;color :#667085 ;width: 216px;height: 44px;right: 0;border-left: 1px solid #D0D5DD; padding-left: 8px;  line-height: 44px; }
} 
.divimage{width: 170px;}
.divleftbutton{width: 390px;}
