.clients{
    position: relative;
    margin-top: 60px;
    padding: 0px 15px 60px 15px;
    height: calc(100vh - 240px);
    &_bottom{
        position: absolute;
        bottom: 0;
        background-color: #FFF;
        bottom: 0;
        left: 0;
        height: 60px; 
        width: 100%;
        border-top: 1px solid  #EAECF0;
        border-radius:  0 0 8px 8px; 
        text-align: right;
        padding: 10px 15px;

     }
}
.client{
    margin-bottom: 20px;

}
.positionrelative{position:relative}
.clients_liste{
    overflow-y: scroll;
    height: calc(100vh - 300px);
    &::-webkit-scrollbar {   display: none;}
}
.client_titre{
display: flex;
justify-content: space-between;
}
.client_titre_left{
    font-weight: 500;
    font-size: 18px;
    color: #101828;
}
.datereception{
    font-weight: 400;
    font-size: 14px;
    color: #98A2B3;
}
.nomchamp{
width: 85px;
 padding-right: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
}
.valchamp{
    position: relative;
    max-width: 362px;
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
}

.tableclients{
    width: 100%;
   tbody td:not(.nomchampville) {
    height:27px ; 
   }
   .nomchampville{
    height:22px ;
   }
   
}

.tdemail{ position: relative; width: 520px;
    div{ cursor: pointer;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 16px;
        height: 27px;
        background-image: url(../../images/copy.svg);
        background-size: 16px;
        background-position:center;
        background-repeat: no-repeat;}
}


