
.ecrangestionfilterleads{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 22px;
    margin-bottom: 0px;
    max-width: 1200px;

}


.ecrangestionfilterleads_btn{
    div{pointer-events: none;}
    position: relative;
    width: 222px;
    height: 70px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between ; 
    padding:12px 15px 12px 15px ;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    cursor: pointer;
   
    &_titre{position: absolute; bottom: 15px;color: #667085;font-size: 12px;font-style: normal;font-weight: 500;height: 10px;  margin-top: 30px;  vertical-align: baseline;&.selected{color: #FF8001;}}
    &_nb{position: absolute; top: 10px; right: 15px; color:  #101828; font-size: 24px;font-style: normal;font-weight: 600;height: 36px;  &.selected{color: #FF8001;}}

    &.selected{color: #FF8001; background-color: #F2F4F7 ;}
    background-position:10px 10px; background-repeat: no-repeat;background-size: 18px; 

    &.choiceleadsrecus{background-image: url('../../images/gestionfilterleads/leadsrecus.svg') ;
                     &.selected{background-image: url('../../images/gestionfilterleads/leadsrecusorange.svg')}}
    &.choicenontraites{background-image: url('../../images/gestionfilterleads/nontraites.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/nontraitesorange.svg')}}
    &.choicenrp{background-image: url('../../images/gestionfilterleads/nrp.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/nrporange.svg')}}
    &.choiceechec{background-image: url('../../images/gestionfilterleads/echec.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/echecorange.svg')}}
    &.choiceencours{background-image: url('../../images/gestionfilterleads/encours.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/encoursorange.svg')}}
    &.choicedevis{background-image: url('../../images/gestionfilterleads/devisenvoyes.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/devisenvoyesorange.svg')}}
    &.choicesignature{background-image: url('../../images/gestionfilterleads/signature.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/signatureorange.svg')}}
    &.choicegagnes{background-image: url('../../images/gestionfilterleads/gagne.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/gagneorange.svg')}}
    &.choicefauxnumeros{background-image: url('../../images/gestionfilterleads/fauxnumeros.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/fauxnumerosorange.svg')}}
    &.choicedoublons{background-image: url('../../images/gestionfilterleads/doublons.svg') ;
                         &.selected{background-image: url('../../images/gestionfilterleads/doublonsorange.svg')}}


}
.dashboutonchoice{
background-color: #F2F4F7;  }