.popuparvertsign{
    position: fixed;
    z-index: 5000;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    height: 50px;
    line-height: 50px;
    border: 1px solid red;
    background-color: pink;
    border-radius: 12px;
    color: red;
    font-size: 12px;
    display: none;
    text-align: center;
    &.yes{
        display: block;
    }
}