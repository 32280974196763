
.groupgender{
    height: 65px;
    padding-top: 20px;
    width: 122px;
    position: relative;
    &_buttons{
        height: 40px  ;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
         &::before{ content:"Titre"; position:absolute; width:122px;height: 14px;line-height: 14px;left: 0px;top:0px;
                   z-index: 10;color: #344054;font-size: 14px;font-weight: 600; }

    }

}