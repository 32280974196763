.telephonie{
    display: inline-flex;
    padding:10px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
     border-radius: 8px;
    // border: 1px solid   #D0D5DD;
     background:  transparent;
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 82px;
}

.telephoniedraggable{
    border: 1px solid red;
    position: absolute;
    right: 50px;
    top: calc(100vh - 350px) ;
    z-index: 5000;
    border-radius: 8px;
border: 1px solid #D0D5DD;
background: #F9FAFB;

box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.disableclick{cursor: none;  pointer-events: none;}
.threepoints{
    cursor: pointer;
}
.telephonietextes{
    display: flex;
    flex-direction: column;
}
.telephonienom{
    display: inline-block;
    color:  #101828 ;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
}
.telephoniestatus{
    display: inline-block;
    color: #98A2B3;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px;
}

.telephonienext{height: 24px; cursor: pointer;  }
.telephonietoclient ,.telephoniemute{height: 36px; cursor: pointer; }



.silence{
    cursor: pointer;
    width: 36px;
    height: 36px;
    border: 1px solid lightgray;
    border-radius: 50%;
    background-color: #FFF;
    background-position: center center;  background-repeat: no-repeat; background-size: 22px  ; 
    &.ismuted{  background-image: url(../../images/muteon.svg);  }
    &.nomuted{  background-image: url(../../images/muteoff.svg);   }

}

.telephoniebtns{
    margin-left: 30px;    display: inline-flex;align-items: center;gap: 10px;
    div{height: 40px; width: 120px;  line-height: 40px;  color: #FFF; font-size: 14px;  text-align: center; border-radius: 8px; cursor: pointer; }
    &_repondre{background-color: #66C61C; border: 1px solid #66C61C; }
    &_reject{background-color: #FF4405; border: 1px solid #FF4405; }

}

