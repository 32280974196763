
.gestionsearch_content{

        position: relative;
        top: 10vh;
        left: 50%;
        transform: translateX(-50%);
        background-color:#FFF ;  
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        border-radius: 12px;
        width:1150px;
        max-height: 80vh;
        padding: 27px  32px;
}

.search_close{
    position: absolute;
    right: 30px;
    top: 30px;
    width: 60px;
    height: 60px;
    cursor: pointer;
   background-image: url(../../images/croixclose.svg);background-position: center center;  background-repeat: no-repeat;
   background-size: 15px 15px;  
}

.search_content_top{
    height: 50px;
    line-height: 60px;
    margin-bottom: 20px;
}

#inputsearch{
    width: calc(100% - 90px);
    height: 44px;
    outline: none;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding-left: 15px;

}



.gestiontablesearch_container{
    height: 69vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {   display: none;}
}