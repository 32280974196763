
.mr25{margin-right: 25px;}
.ml25{margin-left: 25px;}
.inlineblock{display: inline-block;}
.buttonradiowin{ display: inline-block;
    width: 16px;height: 16px; background: #FFFFFF;border: 1px solid #D0D5DD;border-radius: 8px; position: relative; cursor: pointer;  margin-right: 10px;
    &.validated{background: #FFEAD6;border: 1px solid #FF8001;
        &::after{content:'';position: absolute;width: 6px;height: 6px;left: 4px ; top:4px ;background: #E47200;  border-radius: 50%; }     
    }
}    

.linequestioninputradiowin{
    display: flex; justify-content: flex-start;  align-items: center;  height: 20px;  line-height: 20px;
    &_question{height: 20px;font-family: 'Poppins';font-style: normal;font-weight: 500;font-size: 14px;line-height: 20px;color: #344054;}
    &.half{ width: 50%;}  &.all{ width: 100%;}
}

.resiliation_top{

    &_titre{font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
          margin-bottom:10px;}

    &_soustitre{font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 5px; }

    &_questions{ width: 100%;
                  display: flex;
                   flex-wrap: wrap;
                    justify-content:space-between ;
                    height: 100px;
    }

    &_formulaire{ width: 100%;
        display: flex;
         flex-wrap: wrap;
        justify-content:space-between ;
       
    }

}
.contratwin_box_flex_questionnaire{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px}

.resiliation_fin{
    background-color : #F9FAFB;
    padding-top: 105px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-position:  center top 35px ;
    background-repeat: no-repeat; background-size: 48px 48px;
    background-image: url(../../images/resiltop2.svg);
    div{
        width: 60%;
        margin: auto;
        text-align: center;
        margin-bottom: 15px;
    }
  }