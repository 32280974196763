.listevents{
    z-index: 900;
    background-color: #FFF;
    width : var(--divcalendar-width);
    border-left: 1px solid #EAECF0;
    border-bottom: 1px solid #EAECF0;
    &.fixedright{
        position: fixed;
        right: 0px;
        top:0px;
        bottom: 0px ;
        @media screen and (max-width: 1200px) {display: none;}
    }
    &.moveleft{
        position: fixed;
        top:0px;
        bottom: 0px ;
        transition: all 1s;
        border-right: 1px solid  #D0D5DD;
         box-shadow: 1px 1px 1px 0px #D0D5DD;
        &.displaycal{left: 80px;}
        &.nodisplaycal{left: -380px;}
    }
    &_titre{
        height: 68px;
        line-height: 68px;
        font-weight:500;
        color: 101828;
        padding-left: 10px;
        font-size: 20px;
        border-bottom: 1px solid #EAECF0;
        position: relative;
    }
}
.alerte_calendar{
    position: absolute;
    bottom: 0;
    height: 20px;
    line-height: 10px;
    font-size: 10px;
    font-weight: bold;
    color:red;
}

.calendaricons{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    &_changecal{
        cursor: pointer;
        width:20px;
        height: 20px;
        background-position:center center;  background-repeat: no-repeat;
        background-size: 20px 20px;
        background-image: url(../../images/iconchangecal.svg);
    }
    &_tofiche{
        cursor: pointer;
        width:20px;
        height: 20px;
        background-position:center center;  background-repeat: no-repeat;
        background-size: 20px 20px;
        background-image: url(../../images/icontofiche.svg);
    } 
}
 


       
.formatjour{
    font-size: 12px;
    font-weight: 500;
    color: gray;
    padding-left: 15px;
    height: 44px;
    line-height: 44px;
    background-color: #F2F4F7;
}

.cache_rdvjour{
    height: 45px;
    overflow-y: hidden;
}
.cursorpointer{
    cursor: pointer;
}
.formatrdv{
    overflow: hidden;
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid #EAECF0;
 
    &_nopro {background-color:#FFF;}
    &_nopro {background-color:#F5FBFF;}
    &_heuremin{ color: #667085;  line-height: 50px;font-weight: 400;
        font-size: 12px;  text-align:right; width:  72px;  }
    &_summary{  padding-left: 12px;  line-height: 15px; padding-top: 10px;width:  225px;   }
}

.summary{
    position: relative;
    display: flex;flex-direction:column ; justify-content: center;
     padding-left: 12px;  height: 50px;width:  225px; 
    font-size: 12px;color: #101828;font-weight: 500  ; }




.listedesevents{
    height: calc(100vh - 80px);
    overflow: scroll;
    &::-webkit-scrollbar {   display: none;}
}
.calendarstatut{
    height: 20px;
    line-height: 20px;
    color: #FF8001 ;
    display: inline-block;
}
.calendarflamme{
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 50px;
    display: inline-block; margin-left: 15px;
    width:25px;
    background-position:center center;  background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url(../../images/flammehot.svg);
    
}

#divinvisible{
    opacity: 0;
    display: block;
    position: fixed;

}
.calendarevent{
    position: relative;
    color : rgb(3, 155, 229) ;
    border : 1px solid rgb(3, 155, 229) ;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 8px;
    line-height: 12px;
    font-size: 12px;
    span{outline: none}
    &_titre{
        font-weight:600;
    }
    &_details{
      
        background-color: #FFF;
        z-index: 2000;
        position: absolute;
        top: 48px;
        left: -1px;
        border: 1px solid lightgrey;
        color: grey;
        padding: 8px;
        width: calc(100% + 2px);
        height: 100px;
        &_yes{display: block;}
        &_no{display: none;}
    }
    &_info{
        display: inline-block;
        position: absolute;
        right: 5px;
        bottom: 3px;
        width: 15px;
        height: 15px;
        line-height: 15px;
        font-size: 10px;
         font-style: italic;
        border-radius: 50%;
        border: 1px solid gray;
        color: gray;
        cursor: pointer;
        padding-left: 4px;
    //    text-align: center;
    }
}