// .linetelephonie{ height: 72px; display: flex;align-items: center;  border-bottom: 1px solid #EAECF0; 
//     & > div:nth-child(1){ padding-left: 20px ;width :765px ; height: 72px;line-height: 72px; font-size: 14px;color: #667085 ; font-weight: 400;}
//     & > div:nth-child(2){ display: flex;  justify-content: flex-start; align-items: center; }
// }

.nouser{height: 44px;  }
.selectgestionnaire{padding: 0px 10px; height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD; 
    width:100%; cursor: pointer; position: relative;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);border-radius: 8px; outline: none; 
        &.up{background-image:url(../images/dropdownindicup.svg);background-position: right 10px center;background-repeat:no-repeat; background-size: 10px 5px;} 
        &.down{background-image:url(../images/dropdownindicdown.svg);background-position: right 10px center;background-repeat:no-repeat; background-size: 10px 5px;} 
      

}

.listegestionnaires{position: absolute; top: 46px;  left: 0;  width: 100%; background: #FFFFFF;border: 1px solid #F2F4F7;border-radius: 8px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);   z-index: 100;

    &.show{height: auto;max-height: 300px; overflow-y: scroll  ;padding: 10px 10px 0 10px; }  
    &.hide{height: 0px;padding: 0px;border: none; overflow: hidden;}
   }
   .listegestionnairesline{
    display: flex; align-items: center; height: 44px;
     > div, img{pointer-events: none;} 
    img{display: inline-block; border-radius: 50%  ;margin-right: 15px ; }
    .small{width: 20px; height: 20px; margin-left: 5px ;margin-right: 15px ;}
   }

// .selected{
//     display: flex;align-items: center;  height: 44px;  
//     img{display: inline-block; border-radius: 50%;  border: 1px solid lightgray;  margin-right: 15px}
// }