.ficheclienttop{
 height: 160px;
 position: fixed;
 left: calc(var(--sidebar-width));
 width: calc(100% - var(--sidebar-width) );
 background-color: #FFF;
 padding: 15px   35px   10px   35px  ;
}


.ficheclienttop_interieur{
    height: 100%;
    &_part1{
        height: 80px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
}
.ficheclienttopleft{
  display: flex;
}
.iconlisteappels{cursor: pointer; position: fixed;  top: 43px ; right: 38px;}
.disableclick{cursor: none;  pointer-events: none;}
.ficheclienttopleft_flamme{
    cursor: pointer;
    width:50px;
    height: 80px;
    padding: 0 10px;
    background-position:center center;  background-repeat: no-repeat;
    background-size: 30px 30px;
    &.cold{
        background-image: url(../../images/flamgrise.svg);
    }
    &.hot{
        background-image: url(../../images/flammehot.svg);
    }
}
.ficheclienttopleft_avatar{
    height: 80px;
    line-height: 80px;
        .avatar{
            cursor: auto;
            width: 56px ;
            height: 56px;
            line-height: 56px;
            border-radius: 50%;
            background-color:#3084FF;
            font-weight: 500;
            font-size: 20px;
            color: #FFF;
            text-align: center;
        }
}
.ficheclienttopleft_description{
    padding: 10px;
    & > div{
        height: 30px; line-height: 30px;
        font-size: 21px;
        font-weight: 500;
        color: #101828 ;
    };
    & > div:nth-child(2){
        img{border-radius: 50%;}
    }
}


.ficheclienttopright{
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;
}

.statutcontacttop{
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    background-color: #FF8001 ;
    color: #FFF;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    padding: 0 8px;
    border-radius: 8px;
}

.nbappels{
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    background-color: #0068FF    ;
    color: #FFF;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    padding: 0 12px;
    border-radius: 12px;

}

.content_telephonie{
    position: absolute;
    right: 0px;
    top:0;

}

.divactionstel{

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 80px 20px  20px;
    width: auto;
    height: 81px;
    background: #F9FAFB;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    opacity: 0; pointer-events: none;
   transition:opacity  4s  ease-out;  
   border: 1px solid red;
    &.open{ animation: visible 1s; animation-fill-mode: forwards;   pointer-events: all; }

}
@keyframes visible { from {    opacity: 0; } to {    opacity: 1;  }  }



.ficheclienttop_interieur_part2{
    display: flex;

    .progressinfo{
        position: relative;
        width: calc(  (100% - 50px) / 6  );
        height: 34px;
        line-height: 34px;
        color: #FFF;
        &_1{  padding-left: 15px;  z-index: 6; border-radius: 8px 0 0 8px;}
        &_2{ padding-left: 30px; z-index: 5;transform: translateX(10px);}
        &_3{ padding-left: 30px; z-index: 4;transform: translateX(20px);}
        &_4{ padding-left: 30px; z-index: 3;transform: translateX(30px);}
        &_5{ padding-left: 30px; z-index: 2;transform: translateX(40px);}
        &_6{ padding-left: 30px; z-index: 1;transform: translateX(50px);  border-radius:0 8px  8px 0;}
        &.finished{background-color: #FF8001 ;}
        &.finishedechec{background-color: red;}
        &.nofinished{background-color: #C4C4C4 ;}
        &.gagne{background-color: #039855;}
    }
    .svgrigth{ position: absolute; right: -19px; top: 0;height: 34px;
                &.finished{fill: #FF8001 ;}
                &.finishedechec{fill: red;}
                &.nofinished{fill: #C4C4C4 ;}
                &.finishedgagne{fill: #039855;}

             }
    .svgleft{ fill : #FFF ; position: absolute; left: 0px; top: 0; height: 34px;}
}
