.containernotes{
     position: relative;
    margin-top: 45px;
    overflow-y: hidden;
    height:calc(100vh - 230px) ;
    background-color:  #FFF;
    padding: 20px 20px 80px 20px;
    z-index: 1;
}
.listenotes{

    height:calc(100vh - 280px) ;
   padding-bottom: 150px;
    overflow-y: scroll; &::-webkit-scrollbar { display: none; } 
    
}


.manote{
    padding: 16px 24px;
    background-color:#FEEE95;
    margin-bottom: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    &_date{color: #FF8001;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
           margin-bottom: 8px;

    }
    &_texte{
        color:  #667085;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}

.addnote{
    position: relative;
    padding: 42px 24px  16px 24px;
    background-color:#FEEE9580;
    width: 100%;
    border: none;
    outline: none;
    min-height:120px ; 
    
    &_titre{  position:absolute; width: 200px;height: 14px;line-height: 14px;left: 20px;top:15px;
        z-index: 10; color: #FF8001;font-size: 14px;font-weight: 600; }

}

.textarea_container{
    position: relative;

}

.listenotes_bottom{
    position: absolute;
    bottom: 0;
    background-color: #FFF;
    bottom: 0;
    left: 0;
    height: 60px; 
    width: 100%;
    border-top: 1px solid  #EAECF0;
    border-radius:  0 0 8px 8px; 
    text-align: right;
    padding: 10px 15px;


 }


