
.cardequipement{
    position:relative;
    cursor: pointer;
    width: 700px;
    height: 112px;
    display:flex ; 
    background-color: #FFF;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    margin-bottom: 15px;
    z-index:1;
    padding:16px 16px 16px 16px ;
    & > div:nth-child(1){width: 70px; height: 70px;line-height: 70px; border-radius: 50%; 
                        background-color: #667085; color: lightgray; font-size: 10px;    
                        white-space: nowrap;text-overflow: ellipsis;
                        padding: 0 3px;
                        font-weight: bold; text-align: center;  overflow-x: hidden;}
    & > div:nth-child(2){width :350px ; div{height: 18px; line-height: 18px;}}
    & > div:nth-child(3){ width :120px ;}
    & > div:nth-child(4){width :80px ; height: 112px; display: flex; align-items: center;}
}
.cardequipement_texte1{
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #667085;
}
.cardequipement_texte2{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085;
}

.cardequipement_badge{
    display: inline-block;
    background-color: #ECF4FF;
    color: #3084FF;
    font-size: 12px;
    font-weight: 500;
    height: 18px;
    line-height: 18px;
    text-align: center;
    padding: 0 5px;
    border-radius: 6px;


}

.cardequipement_prix{
font-size: 24px;
font-weight: 700;
line-height: 32px;
text-align: left;
color: #101828;

}

.cardequipement_par{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #101828;
}


// .indiccontrat{position: absolute; left: 8px; bottom:0 ;  font-size: 11px;  color:red;font-style: italic;font-weight: bold;}
// .deletecontrat{position: absolute; right: 8px; bottom:0 ;  font-size: 11px;  color:red;font-style: italic;font-weight: bold; cursor: 0;  z-index: 2000; }
// .cardequipement_image{img{display: inline-block; border-radius: 50%; }}
// .cardequipement_formule{ 
//     display: flex; flex-direction: column;align-items: flex-start;width: 167px;  padding-left: 10px;
//     &_line1{color:#101828;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 500;line-height: 20px;}
//     &_line2{color:  #667085;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 400;line-height: 20px; }

// }
// .cardequipement_details{
//     display: flex; flex-direction: column;align-items: flex-start;width:229px; 
//     &_numcontrat{color: #101828;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 700;line-height: 16px;}
//     &_titulaire{color: #101828;font-family: Poppins;font-size: 12px;font-style: normal;font-weight: bold;line-height: 16px;}
//     &_dates{ height: 20px;
//          .graybold{color:  #667085;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 700;line-height: 16px;}
//          .lightgray{ color:  #667085;font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 400;line-height: 16px; }
//     }
// }

// .cardequipement_prix{  width : 120px ; 
//     display: flex;height: 112px;padding: 16px 5px;flex-direction: column;justify-content: center;align-items: center;
//     &_line1{color: #101828;font-family: Poppins;font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;}
//     &_line2{color: #101828; font-family: Poppins;font-size: 13px;font-style: normal;font-weight: 400;line-height: 20px; }
// }
                 