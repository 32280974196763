// &.relance{border: 1px solid #FF8001;color: #FF8001;
//     &:hover{background-color: #FF8001; color:#FFF;   }}

.boutonsimple{font-weight: 400;   padding: 0 15px;
    &.loading{
        padding-left:40px;
        opacity: 0.5;
        cursor: none;
        pointer-events: none;
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          position: absolute;
          top: 10px;
          left: 10px;
          background: url("../../images/loadingwhite.svg") no-repeat center/cover;
          animation: rotate 4s infinite linear;
        }
      }
}


@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
@-webkit-keyframes rotate {
    from {-webkit-transform:rotate(0deg);}
    to {  -webkit-transform:rotate(360deg);}
  }
  
  @-moz-keyframes rotate {
    from {-moz-transform:rotate(0deg);}
    to {  -moz-transform:rotate(360deg);}
  }

// import imageloader from "../../images/loading.svg"
.imagerotate{
    width: 20px;
    animation: rotation 2s infinite linear;
  }

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }


.boutonbase{
    position:relative ; 
    display: inline-block;
    border-radius: 8px;
    text-align: center;
    cursor: pointer; 
    
}

.boutondisable{
    opacity: 0.5;
    pointer-events: none;
}
.containerbouton{width: auto;}

.boutonimgleft{ font-weight: 500;   padding: 0 10px  0 40px;  background-position:10px center; background-repeat: no-repeat;background-size: 18px ;}
.boutonimgright{ font-weight: 500;  padding: 0 40px  0 15px;    background-position:right 10px center; background-repeat: no-repeat;background-size:20px  auto;}
.interieurbouton{display:flex; flex-direction: column;  align-items:center;justify-content: center; height:100% ;line-height:100% ; span{display:block;  color:#F3F4F6; line-height: 16px; height: 18px; }}
.boutonimgtop{width : 91px ; height: 61px; padding-top:25px; padding-bottom: 5px;   background-position: center  top 5px ; background-repeat: no-repeat;background-size:19px;font-weight: 500  ; font-size: 14px ; line-height: 14px  ; span{color: inherit;}}
.fullbuton{display: flex;width: 100%;  justify-content: center;  background-color:  #ff8001; border-radius: 8px; cursor: pointer;  &.white{background-color: #FFF;    border: 1px solid #D0D5DD;} }
.buttonpaddinglr50{padding: 0 50px;}

.bouton_size_normal{
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}

.bouton_size_small{
    font-size: 14px;
   padding: 3px 15px;
}

.bouton_size_medium{
    height: 48px;
    line-height: 48px;
 }
 .bouton_size_medium{
    width: 100%;
 }

 .bouton_color_black{
    border: 1px solid black;
    color: #FFF;
    background-color:  black;
}

 .bouton_color_orange{
    border: 1px solid #ff8001;
    color: #FFF;
    background-color:  #ff8001;
}


.bouton_color_violet{
    border: 1px solid  #7839EE;
    color: #FFF;
    background-color:   #7839EE;
}


.bouton_backcolor_orange{
    border: 1px solid #ff8001;
    color:#ff8001;
    background-color:  #FFF;
}

.bouton_backcolor_orangelight{

    color:#ff8001;
    background-color: #FFF9F3;

}
.mbb10{margin-bottom: 10px}
.bouton_color_blancgris{
    border: 1px solid #D0D5DD;
    background-color: #FFF;
   color:  #344054;
   font-weight: 500;
   &.noborder{border: transparent;     background-color: transparent;}
}

.bouton_color_grisblanc{
    border: 1px solid #D0D5DD;
    color: #FFF;
    background-color:  #D0D5DD;
   font-weight: 500;
   &.noborder{border: transparent;     background-color: transparent;}
}


.bouton_color_disable{
    color: #FFF; background-color: lightgrey; border: 1px solid  lightgrey; cursor:auto ;  pointer-events: none;
}


.bouton_nrp{
    border: 1px solid  #9DA4AE;color: #9DA4AE;background-size:20px;
     background-image: url(../../images/images_tel/iconindispo.svg);
    &:hover{background-color:#9DA4AE ; color:#FFF;  
               background-image: url(../../images/images_tel/iconindispowhite.svg);
    }
}

.bouton_maintien{
    border: 1px solid  #0068FF;color: #0068FF; 
     background-image: url(../../images/images_tel/iconmaintien.svg);
    &:hover{background-color: #0068FF ; color:#FFF;  
               background-image: url(../../images/images_tel/iconmaintienwhite.svg);
    }
}
.bouton_relance{
    border: 1px solid #FF8001;color: #FF8001;
     background-image: url(../../images/images_tel/iconrelance.svg);
    &:hover{background-color: #FF8001  ; color:#FFF; 
               background-image: url(../../images/images_tel/iconrelancewhite.svg);
    }
}
.bouton_rdv{
    border: 1px solid #FF8001;color: #FF8001; background-size:16px;
     background-image: url(../../images/images_tel/iconagenda.svg);
    &:hover{background-color:#FF8001 ; color:#FFF; 
               background-image: url(../../images/images_tel/iconagendawhite.svg);
    }
}


.bouton_fauxnum{
    border: 1px solid  #6C737F;color: #6C737F;
     background-image: url(../../images/images_tel/iconfauxnum.svg);
    &:hover{background-color:#6C737F ; color:#FFF;
               background-image: url(../../images/images_tel/iconfauxnumwhite.svg);
    }
}
.bouton_echec{
    border: 1px solid  #384250;color: #384250; 
     background-image: url(../../images/images_tel/iconechec.svg);
    &:hover{background-color: #384250 ; color:#FFF;  
               background-image: url(../../images/images_tel/iconechecwhite.svg);
    }
}




.bouton_color_rouge{
    border: 1px solid red;;
    color: #FFF;
    background-color:  red;;
}
.bouton_color_vert{
    border: 1px solid #12B76A;
    color: #FFF;
    background-color: #12B76A;
}
.bouton_color_bleu{
    border: 1px solid  #3084FF;
    color: #FFF;
    background-color:   #3084FF;
}

.minipadding{padding-left: 3px;}


.imageicongagne{background-image: url(../../images/icongagne.svg)}
.imagemail{background-image: url(../../images/envoimessage.svg)}
.imageplus{background-image: url(../../images/plus.svg)}
.imageechec{background-size: 20px ; background-image: url(../../images/images_tel/iconechecwhite.svg);}
.imagerdv{ background-image: url(../../images/images_tel/iconagendawhite.svg);}
.imagerelance{background-size: 20px ;  background-image: url(../../images/images_tel/iconrelancewhite.svg);}
.imagemessagerie{background-size: 20px ;  background-image: url(../../images/iconmessagerie.svg);}
.imagerecyclage{background-image: url(../../images/btn_recyclage.svg)}
.imagevaliddocs{text-align: left; background-image: url(../../images/iconbtnvaliddocs.svg)}
.imagesenddevis{background-image: url(../../images/iconsenddevis.svg)}
.imagedownloaddevis{background-image: url(../../images/icondownloaddevis.svg)}
.imagesignature{background-size: 20px ; background-image: url(../../images/imagesigne.svg)}
.imagefusee{background-image: url(../../images/fuseeverte.svg);}


.senddevis{background:#FF8001; width: 173px ;padding: 0 ;}
