
.divalertcontrat{
    position: absolute;
    top: 10px;
    left: 50%;
    line-height: 15px;
    z-index: 5000;
    text-align: left;
    transform: translateX(-50%);
    padding: 20px;
    width: 400px;
    height: 156px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_top{
        height: 56px;
        line-height: 56px;
        font-size: 18px;
        color: red;
        font-weight: 500;
        padding-left: 70px;
        background-image: url(../../images/alertpbcontrat.jpg);
        background-position: left center;  background-repeat: no-repeat; background-size: 56px 56px;
    }
    &_bottom{
        display: flex;justify-content: space-between;
        
        &_left{  height: 40px;  line-height: 20px;  font-size: 14px;  color: #667085;
                    span{color: red;  font-weight: 600;}
        }
        img{display: inline-block;}
    }
}