.administration{
    position: relative;
    padding-left: calc(var(--sidebar-width) + 1px)  ; 
     min-height: 100vh ;
     overflow-y: scroll;
    background-color: #F9FAFB;
    // padding-top: 32px;
  
}

#containerringover{
    position: relative;
    border:1px solid red; 
    top:5px;
    right: 5px;
    width: 250px;
    height: 50px;
}

.contentanimationlottie{
    position: absolute;
    background-color: transparent;
    z-index: 3000;

}



.divalertnone{
    display: none;
}
.divalertblock{
    display: block;
}


#aircallphone{
    position: fixed;
    bottom: 50px;
    right:50px;
    z-index: 3000;
    width: 376px;
    height: 600px;
    &.displaynone{display: none;}

}

