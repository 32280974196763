.popupmultitel{position: absolute;
    top:  100px;
    right: 25px;
    flex-direction: column;
    align-items: center;
    padding: 22px 16px 0px 16px;
    gap: 23px;
    width: 260px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    z-index: 6000;

    div{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        cursor: pointer;
        color: #FFF;
        background-color : #4CA30D;
        margin-bottom: 22px;
        text-align: center;
        border-radius: 12px;

    }
}