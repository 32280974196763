.ecransuperadmin{
    z-index: 10;
    background-color:  #F9FAFB; 
    width: 100%;
    padding: 30px;
    &_grpmenu{
        display: flex;
        justify-content: space-between;
    }
    &_titre{
        font-weight: 500;font-size: 30px; color: #101828;  margin-bottom: 15px;
    }
    &_menu{
        border: 1px solid  #D0D5DD;

        border-radius:  8px ;
        height: 40px;
        display: inline-block;
        background-color: #FFF;
        overflow: hidden ;
        div:not(:last-child){ border-right: 1px solid #D0D5DD;}
        div{ padding: 0 16px;
            height: 40px;
            line-height: 40px;
            display: inline-block;
            font-weight: 500;
            font-size: 14px;
            color: #344054;
            cursor: pointer;
            &.selected{background-color:  #98A2B3;color: #FFF}
        }
    }
}


