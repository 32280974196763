
.tabgestion{

    background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  display: inline-block;
    width: auto;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    margin-bottom: 25px;
    &_titre{
        height: 28px;
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        color: #101828;
        margin-bottom: 10px;

        &.ecranclients{
            padding-top: 10px;
            display: flex;
            height: 50px;
            margin-bottom: 10px;
            justify-content: space-between;
            width: 1000px;
        }
    }
}

.selcom{
    outline: none;
    color: lightgray;
    border: 1px solid lightgray; 
    border-radius: 4px;
}

.navigation{
    display: flex;
    position: absolute;
    right: 0px;
    top: 0;
    height: 22px;
    line-height: 22px;
  
}

.moins{
    font-size:22px;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
}
.plus{
    font-size:22px;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
}

.indicpage{
    font-size:17px;
}

table{
    border-collapse: collapse ;
    table-layout: fixed;
}
thead{
    background-color: #F2F4F7;
    color: gray;
    color: #667085;
    font-weight: 400;
    
    font-size: 12px;

    font-weight: 400;
    td{height:26px;  line-height: 26px;  
        .coll1{display: inline-block;   width: 150px;}
        .coll2{display: inline-block;   width: 50px;text-align: center;}
        .coll3{display: inline-block;   width: 150px; padding-left: 10px;}
        .coll4{display: inline-block;   width: 250px; padding-left: 10px;}
        .coll5{display: inline-block;   width: 200px; padding-left: 10px; position: relative;}
        .coll6{display: inline-block;   width: 100px; padding-left: 10px;}
    }

}


.tbodytabgestion tr{
    // &:nth-child(even){background-color: #FFF;}
    // &:nth-child(odd){background-color: var(--gray-cool--50);}
    // border-bottom: 1px solid #0068FF;
    cursor: pointer;
    color: gray;
    font-weight: 400;
    font-size: 14px;
    height: 26px; 
    border-bottom: 1px solid #EAECF0;
}


.tbodytabgestion{
    td {height: 26px; }
    td:not(.last_tr){padding: 0 10px;}
    td{height:26px;  line-height: 26px; span{ overflow: hidden;}
        .coll1{display: inline-block;   width: 150px;  }
        .coll2{display: inline-block;   width: 50px;text-align: center;}
        .coll3{display: inline-block;   width: 150px; padding-left: 10px;}
        .coll4{display: inline-block;   width: 250px; padding-left: 10px;}
        .coll5{display: inline-block;   width: 200px; padding-left: 10px; position: relative;}
        .coll6{display: inline-block;   width: 120px; padding-left: 10px;}
    }
}
.caseimg{
    text-align: center;
    position: relative;
    z-index: 0;
  img{border-radius: 50%;vertical-align: middle; }  

 
}