.ecrangestiontop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 1200px;
}

.cursor , .cursordate  {display: inline-block;  background-position: 0px center;  background-repeat: no-repeat;background-size: 36px 20px;
    cursor: pointer;  height: 20px;width: 40px; margin: 0 5px;
    &.yes{ background-image: url(./toogleyes.svg);   } 
    &.no{ background-image: url(./toogleno.svg);   } 
   }
.ecrangestiontop_left{
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    &_nomcommercial{
        height: 38px;
        font-family: Poppins;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: left;
        color: #101828;

    }
    &_tablobord{
        display: flex; line-height: 20px;height: 20px;
    }
    &_tablobord_1{height: 20px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 700;
        height: 20px;
        line-height: 20px;
        text-align: left;
        color: #344054;
        display: flex;
        }
        &_tablobord_2{  
            margin-left: 25px;
            display: flex;
            height: 20px;
            div{font-size: 14px;font-weight: 500;line-height: 20px;color: #344054;}
         }
}