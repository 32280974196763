
.ecrandev{
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {   display: none;}
    padding: 25px;
    background:lightgrey;
}
.devcontrat{
    background-color: #FFF;
    border: 1px solid red ;
}
