.infoscontrat{
    max-height:calc(100vh - 260px) ;  
    margin: 0 15px;
    font-size: 16px;
}
.contenttwobuttons{display: flex; gap: 20px;}

.nomcompagnie{
    font-weight: 600; 
}

.listecontrats{
    margin-top: 0px;
    overflow-y: scroll;
    height: calc(100vh - 250px);
    &::-webkit-scrollbar {   display: none;}
}


.btn_open_resiliation{
    display: inline-block;  margin-bottom: 10px;
    color: #FFF;padding:0 15px;   ;height: 36px; line-height: 36px; text-align: center; cursor: pointer; background: #FF8001;
    border: 1px solid #FF8001; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); border-radius: 8px;
}




.signaturenonlistee{border-radius: 8px;border:  #D0D5DD;background:  #F9FAFB;   justify-content: center;
                    display: flex;padding: 24px;flex-direction: column;gap: 10px; align-items: center; margin-bottom: 10px;
                    &_texte{color: #667085;text-align: center;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;
                            &_bold{font-weight: 700;} 
                            &_orange{color:  #FF9123;text-decoration-line: underline; cursor: pointer;} }

}


.infoscontrat_top{height: 40px; margin: 20px 0; display: flex; justify-content: space-between; align-items: center; }
.infoscontrat_top_choice{ display: inline-block;height: 38px;border-radius: 8px ;
        & > div:nth-child(1){display: inline-block;height: 36px;line-height: 36px;vertical-align: middle;
                                padding: 0px 12px ;border-radius: 6px 0px 0px 6px; 
                                font-size: 12px; font-weight: 500;cursor:pointer ;
                                background-color: #FFFFFF; color: #667085;
                                border: 1px solid #667085 ;
                              &.selected{color: #FFFFFF;background-color: #667085;}  }
        & > div:nth-child(2){display: inline-block;   height: 36px;line-height: 36px;vertical-align: middle;
                                padding: 0px 12px ;  border-radius: 0px 8px 8px 0px; 
                                font-size: 12px; font-weight: 500; cursor:pointer;
                            background-color: #FFFFFF; color: #667085;
                            border: 1px solid #667085; 
                            &.selected{color: #FFFFFF;background-color: #667085;} }
}

.typecontratselected{color: #FFFFFF;background-color: #667085;}

