$tablotop-height:  225px; 
$tablo-height:  874px; 

.col1block{display: grid;  grid-template-columns: 40px 147px ;
    &_divimage{  display: flex; align-items: right;  justify-content: center;     svg{display: block; width: 24px;}}
    &_texte{ padding-left: 10px; padding-top: 10px; padding-bottom: 10px; }
    &_titre{ height: 20px;font-weight: 500;font-size: 14px;line-height: 20px;color: #101828;    }
    &_line{height: 18px;font-style: normal;font-weight: 400;font-size: 10px;line-height: 18px;color: #667085;  &_bold{font-weight: bold;} }    
}

.formulesdevis_container{
    position: fixed;
    overflow-y: scroll;
   margin-left: 15px;
    &::-webkit-scrollbar {  display: none }
    height:calc(100vh - 246px)  ;
}

.formulesdevis{
    display: flex; border-radius: 8px 8px 0px 0px;height: $tablo-height;background: #FFFFFF;border: 1px solid #EAECF0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.formulesdevis_top{height:$tablotop-height ;border-bottom: 1px solid #EAECF0;background-color: #FFF9F3; 
                    display: flex; justify-content: center;flex-direction: column; gap:10px ;    align-items: center; 
}

.formulesdevis_firstcol{ width: 190px;  height: $tablo-height; border-right: 1px solid #EAECF0; }

