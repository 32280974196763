.parametresmodal{
    position: relative;
    top: 10vh;
    background-color:#FFF ;  
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;overflow: hidden;  margin: auto;
    padding: 90px 15px 20px 15px ;
    background-position:center 25px;  background-repeat: no-repeat;
    background-size: 50px 50px;
}
.bigtablo{
    background: #FFFFFF;border: 1px solid #EAECF0;
   border-radius: 8px;margin-top: 30px;   
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.tablogamme{border-bottom : 1px solid   #D0D5DD;padding: 15px 0;}

.overflowyscroll{overflow-y: scroll;&::-webkit-scrollbar {display:none;}}

.groupeboutons{  display: flex;  gap: 20px; width: calc(100% - 30px)  ;}

.parametres_titre{font-weight: 500;font-size: 18px;line-height: 28px;color: #101828;}
.noselected{margin: auto  0px;width: 15px; height: 15px; cursor: pointer; background-image: url(./checkboxbase.svg);
    background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;} 

.isselected{margin: auto  0px;  width: 15px;height: 15px;cursor: pointer;   background-image: url(./checkboxvalidborder.svg);
    background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;}


.notvalid{cursor:pointer ; background-image: url(./checkboxbase.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;} 
.isvalid{cursor:pointer ; background-image: url(./checkboxvalid.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;}