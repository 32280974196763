@import '../EcranParametres.scss' ;

.utilisateurajoutmodif{
    width: 692px;
    height: auto;
    background-image: url(./../ajoutuser.svg);
}


.utilisateurajoutmodif_role{height: 15px; line-height: 15px;width: 50%; display: flex;
    &_texte{ padding-left: 10px;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color:   #344054; }
}
.equipeajoutmodif_infosorigine{
    display: flex; margin-bottom: 25px;
    & > div{padding-left: 40px;padding-right: 30px;  font-size: 14px; font-weight: 500;line-height: 20px;text-align: left; 
            background-position: 0px center;  background-repeat: no-repeat;background-size: 36px 20px;
            cursor: pointer;
            &.yes{ background-image: url(./../toogleyes.svg);   } 
            &.no{ background-image: url(./../toogleno.svg);   } 
           }
}
.listeattibuts{display: flex; margin : 10px 0}
.listeselect{display: flex;  justify-content: flex-start; gap: 15px ; height: 50px; }
.selectlisteequipes{width:calc(50% - 15px) ;
    padding: 10px 14px;gap: 8px;height: 44px; line-height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
   border-radius: 8px; outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;       
   }

.placevide{width:calc(50% - 15px) ; padding: 10px 14px;height: 44px; line-height: 44px; background: #FFFFFF; }