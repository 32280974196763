$tablotop-height:  225px; 
$tablo-height:  874px; 


.coll1block{
    &_titre{ height: 20px;font-weight: 500;font-size: 14px;line-height: 20px;color: #101828;  }
    &_line{height: 18px;font-style: normal;font-weight: 400;font-size: 10px;line-height: 18px;color: #667085;
       }    
}

.containeracesstodocs{display: flex; justify-content: center; height: 70px;}

.devisformule{
    width: 166px;border-right: 1px solid #EAECF0;
    div{text-align: center;}
    &_last{border-right: none ;}
    &_top{ height: $tablotop-height;border-bottom: 1px solid #EAECF0;
          &_img{height:80px;display: flex; justify-content: center;align-items: center; 
            img{display: inline-block; border-radius: 50%; } }}
    &_nomgamme{font-weight: 500;font-size: 12px;line-height:12px; height: 24px; color: #101828;}  
    &_formulenom{height: 20px;font-weight: 400;font-size: 14px;line-height: 20px;color: #667085;margin-bottom: 10px;  }
    &_prix{ height: 44px; font-weight: 700;font-size: 24px;color: #101828;  border-bottom: 1px solid #EAECF0 ;
 
    }   
    &_parmois{font-weight: 400;font-size: 10px;color: #101828;  vertical-align: middle; }
    &_actions{height: 50px; padding: 0 10px;  display: flex; justify-content: center; align-items: center;}
}

.blockinfopost{
        display: grid;
    grid-template-columns: auto 30px;
    padding : 10px  0; 

}
