.popupradin{
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width:1200px;
    height: 638px;
    padding: 100px 20px 60px 20px;
    border-radius: 12px;
    background: #FFFFFF;
    background-position: center  top 40px ;
    background-repeat: no-repeat;
    background-size:40px 40px ;
    background-image: url(../../images/fuseeorange.svg);
    &_middle{
        width: 800px;
        margin: 30px auto ;
        &_1{ margin-bottom: 20px;
            background-image: url(../../images/radin.svg);background-position: right center;  background-repeat: no-repeat;
            background-size: 208px 200px;  
        }
    }
    &_titre{font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
            color: #101828;


    }
    &_texte{font-size: 18px;
            font-weight: 400;
            line-height: 38px;
            text-align: center;
            color: #667085;

            &.center{  text-align: center;}
            &.left{  text-align: left;}

            &_blue{  color:#3084FF; }
            &_orange{color: #FF8001; font-weight: bold;  }
    }
    &_btns{
        width: 800px;
        margin: 30px auto ;
        display: flex;
        gap: 10px;
    }


}

.wd550{width: 550px;}


