
.infouser{
        z-index: 500000;
        position: absolute;
        left: 80px;
        bottom:15px ;
        width: 329px;
        border-radius: 8px;
        border: 1px solid var(--gray-100, #F2F4F7);
        background: var(--base-white, #FFF);
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }
  .infouser_top{
    display: flex;
    padding: 12px 16px;
    &_right{line-height: 20px; text-align: left; padding-left: 20px; }
    &_nomprenom{color: #344054;font-size: 14px;font-style: normal;font-weight: 500;}
    &_emailtel{color: #667085;font-size: 14px;font-style: normal;font-weight: 400;}
  }
  .infouser_changemdp{display: flex; padding: 10px 16px; height: 40px;}
  .infouser_textchangemdp{ color: #344054; font-size: 14px;font-style: normal;font-weight: 400;padding-left: 15px;  cursor: pointer; }
  
  .infouser_bottom{border-top: 1px solid  #F2F4F7;}