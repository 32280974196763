.changemdp{
    position: relative;  border-radius: 12px; padding: 20px  16px; gap: 23px; width: 800px; background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    margin: 50px auto; 
}


.changemdp_titre{font-weight: bold;  font-size:22px ; margin-bottom: 15px;text-align: center;}
.changemdp_line{margin-bottom: 8px;  height: 44px;
                display: flex;
                &_text{width: 400px;height: 44px; line-height: 44px; margin-left: 20px;  font-size: 20px; }  
                &_input{width: 320px;  padding: 10px 14px;gap: 8px;height: 44px; line-height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                       border-radius: 8px; outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;   }
}

.changemdp_lost{text-align: center;}