@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family:'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

input:hover, input:active, input:focus, input:valid{
  outline:none ;
  -webkit-appearance:none;
  box-shadow: none ;
 /* background-color:transparent!important; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.disablebuttons{opacity: 0.35 ;   pointer-events: none  ; }

:root{
  --sidebar-width: 82px; 
  --client-right: 734px;
  --fiche-client: 460px;
  --menu-width : 210px;
  --client-top : 177px;
  --divleads-width: 834px; 
  --divcalendar-width: 300px
}

a{text-decoration: none;}

.containergray{
  position: fixed;
  left: 70px;
  top:0;
  right: 0;
  bottom: 0;
  width: calc(100% - 70px) ;
  height: 100vh;
  background-color: #10182880;
  z-index: 4000;
}
.blur{filter: blur(5px);}