.contratsgestion{
    background-color:#FFF; z-index: 1; overflow-y: scroll ;&::-webkit-scrollbar {display: none; } ; height:calc(100vh - 100px)  ; 
        padding:0 10px; border-radius: 10px;  width: 600px;
        h3{margin: 5px;}
}

.gestioninfoscontrat_line{
    display: flex;
    height: 30px;
    align-items: center;
    &_label{width: 130px;}
    span{display: inline-block; height: 25px; line-height: 25px;width: 300px; overflow-x: hidden;} 
}

.liste_contrats{
    max-height: 95vh;  overflow-y: scroll ;&::-webkit-scrollbar {display: none; }
}
.displayflex{display: flex; gap: 10px;}
.bold{font-weight: bold;}
.btnenregistrer{
    color: #FFF;
    background-color: green;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
   cursor: pointer;
    border: none;
    outline : none ; 
margin-right: 15px;
}
.btneffacer{
    color: #FFF;
    background-color: red;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
   cursor: pointer;
    border: none;
    outline : none ; 
    margin-right: 15px;
}
.btnnouveaucontrat{
    color: #FFF;
    background-color: #F68B0A;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
   cursor: pointer;
    border: none;
    outline : none ; 
    margin-right: 15px;
}


.client{
    margin-bottom: 20px;

}

.clientsliste{
    overflow-y: scroll;
    width: 350px;
    height: calc(100vh - 180px);
    &::-webkit-scrollbar {   display: none;}
}
.client_titre{
display: flex;
justify-content: space-between;
}
.client_titre_left{
    font-weight: 500;
    font-size: 18px;
    color: #101828;
}
.datereception{
    font-weight: 400;
    font-size: 14px;
    color: #98A2B3;
}
.nomchamp{
    width: 120px;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
}
.valchamp{
    width: 210px;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
}
.contratcommercial{
    font-size: 22px;
    font-weight: 600;
    color : grey
}
.tableclientsgestion{
   tbody td:not(.nomchampville) {
    height:22px ;
   }
   .nomchampville{
    height:17px ;
   }
}

.ficheclientcontainer{
    display: flex;
    justify-content: space-between;
}

.infoformulechoisie{
    display: inline-block;
    height: 10px;
    line-height: 10px;
    margin-left: 150px;
    font-size: 10px;
    font-weight: bold;
    color: red
}

.infoscontrat{
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 16px;
}

.gestiondatacontrat{
    background-color: #EAECF0;
   padding-left: 4px;
    min-width: 150px;
    margin-left: 15px;
    border: none;
    // height: 23px;line-height: 23px;
    white-space: nowrap; 
    // vertical-align: middle;
    overflow-y:  hidden;
    &:focus{
        outline: none;
    }
    br{display: none;}
}

.nomcompagnie{
    font-weight: 600;
}

.datecontrat{
    font-style: italic;
    font-weight: 600;
}


.box{
   display: inline-block; width: 12px; height: 12px;  border-radius: 3px; border :1px  solid  gray;   background-color: #FFF;  margin-right: 5px;
    &.selected{background-color : #EAECF0;} 

}

.grpboxstatut{margin-right: 25px;
  
}



.logocopy2{
    cursor: pointer;
     margin-left: 5px;
    width: 16px;
    height: 17px;
    background-image: url(../../images/copy.svg);
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
}