





.minicases{

 display: flex;
 justify-content: left;
width: 100%;
 flex-wrap: wrap;
 gap: 4px;
 margin-bottom: 15px;

}
.minicase{
    width: 21px;
    height: 21px;
    line-height: 21px;
    color: #ff8001;
    background-color: #FFF;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    &.selected{
        background-color: #ff8001;
        color: #FFF;
    }

}
