.boutonsouscrire{
    border-radius: 8px;
    cursor: pointer; 
    padding: 30px 5px 5px 5px;
    width: 67px;
    height: 61px;
    border: 1px solid  #12B76A ;

    background-position: center  top 8px ;
    background-repeat: no-repeat;
    background-size:18px 18px ;
    background-image: url(../../images/fuseeverte.svg);
    background-color: #FFF;

    &_texte{
        text-align: center;
        font-weight: 500  ;
        font-size: 10px ;
        height: 14px  ;
        line-height: 14px  ;
        color:#12B76A ;
        margin-bottom: 2px;
    }
    &_avancement_container{
        width: 47px;
        height: 4px;
        background: #D0D5DD;
        margin: auto;
    }
 }
