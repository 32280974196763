
.contratwin2{
    width: 703px;
    margin: auto
}

.formuleenregistree{
    margin-bottom: 20px;
}
.selectcompgamme{width:calc(100%) ;
     padding: 10px 14px;gap: 8px;height: 44px; line-height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px; outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;       
    }
.contratwin2_top{
            margin: auto;
            display: flex;
            gap:10px;
            flex-direction: column;
            border-radius:8px ;
            justify-content: space-between;
            background-color: #F2F4F7;
            padding: 20px ;
            color: #667085;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            span{font-weight: 700;}
            margin-bottom: 15px;
            &_btn{
                border-radius: 8px;
                margin: auto;
                width: 142px;
                height: 36px;
                background-color: #FF8001;
                color: #FFF; 
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 36px;
                text-align: center;
                cursor: pointer;

            }

            }

.contratwin2_divformext{
    position: relative;
    width: 703px;
    // height: 392px;
    padding: 24px;
    background-color: #F9FAFB;
    border-radius: 8px;
    &_titre{font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #101828;
            margin-bottom: 15px;
    }
    &_form{ width: 100%;  display: flex; flex-wrap: wrap; gap: 18px;
    }
}


.contratext_labelinput{
    width:calc(50% - 10px) ; position : relative ;
    &.smallinput{width:calc(33% - 10px) ; }
    &.miniinput{width:calc(24% - 10px) ; }
    &.deuxtiers{width:calc(66% - 10px) ;}
    input{width: 100%;  padding: 10px 14px;gap: 8px;height: 44px; line-height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px; outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;       
        } 
}

.contratwin2_divformext_confirme{
    width: 703px;
    height: 64px;
    padding-right: 15px;
    display: flex;
    justify-content: right;
    align-items: center;
}