


#user_input_autocomplete_address{z-index: 30100;position: relative;}
.logocopy{
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 10px;
    width: 16px;
    height: 17px;
    background-image: url(../../images/copy.svg);
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
}
#user_input_autocomplete_address{  width: 92%;}
.grouptitleinput{
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    &.grpadresse{ width: 580px;  }
    &.gender{width: 122px;}


   
    &_titre{
    
        color: #344054;
        font-size: 14px;
        font-weight: 600;
    }
    &_containerinput{
        position: relative;
        border: 1px solid #cfd4dc;
        height: 40px;
        background-color: #FFF;
        padding-left:10px ;
        border-radius: 8px;
        &.email , &.email2{width:260px;}
        &.telephone , &.telephone2, &.telephone3{width: 150px;}
        &.iban1 , &.iban2{width: 330px;  }
        &.bic1 , &.bic2{width: 200px;  }
        &.nom , &.prenom{width: 252px; }
        &.complement_adresse{width: 260px; }
        &_label{
            position:absolute; height: 14px;line-height: 14px;left: 0px;top:-20px;
            z-index: 10;color: #344054;font-size: 14px;font-weight: 600; 
        }
        &_labelobliged{
           color: red; display: inline-block; margin-left: 8px;
        }



    
        &.focus{
            border: 1px solid #ff8001;
        }
        &.valid{
            background-image: url(../../images/images_infosassures/checkcircle.svg);
            background-position: right 10px center; 
            background-repeat: no-repeat
        }
        &.error{
            background-image: url(../../images/images_infosassures/errorcircle.svg);
            background-position: right 10px center; 
            background-repeat: no-repeat
        }
        &.obliged{
            border: 2px solid red ;
        }

        input{
            background-color: transparent !important;
            height: 37px;
            border: none !important;
            width: calc(100% - 30px);
 
        }
    }
}

