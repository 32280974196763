.divechecs{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto; 
    padding: 20px 16px 16px;
    gap: 14px;
    width: 343px;
   background: #FFFFFF;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    .closeechecs{display: block; position: absolute;  top: 15px; right: 15px;  cursor: pointer;}
} 
 
        
.divechecs_titre1{
    font-weight: 500;
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #101828;
}
.divechecs_titre2{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}
.divechecs_btn{
    cursor: pointer;
    width: 311px;
text-align: center;
height: 44px;
line-height: 44px;
background: #FF8001;
border: 1px solid #FF8001;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-size: 16px;
color: #FFFFFF;
}