.ticket{ width: 290px; padding:23px ;  cursor:pointer ;  background-color: #FFF; margin-bottom: 10px;}

.ticket_top{height: 40px;   margin-bottom: 10px; display: flex;  justify-content: space-between; align-items: center;
             padding-left: 30px;    background-repeat: no-repeat;background-size: 21px 21px; 
            background-image: url(../../images/tickettel.svg);background-position: left  0px center;
             
}

.ticket_top_left{height: 40px; line-height: 40px;  text-align: left; font-weight: 400;  font-size: 10px;color: #FF9123;    }


.ticket_detail{    margin-bottom: 7px;
    &_line1{  height: 24px; font-weight: 500;font-size: 16px;line-height: 24px;color: #101828; }
    &_line{ height: 18px;font-weight: 500; font-size: 12px;line-height: 18px;color: #667085;  }
}

.ticket_categorie{display: inline-block;  height: 18px; font-weight: 500;font-size: 12px;line-height: 18px;
            text-align: center;color: #E47200; background: #FFEAD6;  border-radius: 16px;  padding: 0 6px ; 
}

.ticket_tag{display: inline-block;  height: 18px; font-weight: 500;font-size: 12px;line-height: 18px;
    text-align: center;color: #015DE2 ;  background: #ECF4FF;  border-radius: 16px;  padding: 0 6px ; margin-right: 6px;
}


.modalgrpbtn{padding: 23px ; display: flex; justify-content: center; gap: 10px; }            

.ticket_soustitre{padding-left:40px ; padding-top:23px ; font-weight: 700; font-size: 14px;line-height: 20px;color: #475467}
.ticket_resume{padding:23px 40px ; font-weight: 500;font-size: 12px;  line-height: 14px;color: #667085;}
.ticket_transcription{padding:23px 40px; font-weight: 500;font-size: 12px;line-height: 14px; color: #667085;}
.ticket_divider{height: 1px; background: #EAECF0;}



.audioplayer{display: flex;justify-content: center; align-items: center; padding: 0px 12px;
             width: 320px;height: 36px;background: #8EBCFF;border-radius: 8px;  margin: 23px auto;
            &_btn{width: 20px;  height:30px ;  height: 30px;background-position: left  center;  background-repeat: no-repeat;background-size: 12px 14px; 
            &.play{ background-image: url(../../images/play.svg) }
            &.pause{ background-image: url(../../images/pause.svg) }   }
            &_progress{ position: relative  ; z-index: 1  ; height:8px ; border-radius: 4px; width:280px   ; background:#B0D0FF; } 
            &_filled{position: absolute ; z-index: 2 ;height:8px ; border-radius: 4px;  width:0px   ; background: #FFF;} 
} 