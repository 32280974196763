@import '../EcranParametres.scss' ;

.equipeajoutmodif{ width: 692px; height: auto; background-image: url(./../popupequipe.svg);}

.equipeajoutmodif_titre{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #101828;

}


.equipeajoutmodif_soustitre{
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #101828;
    margin-bottom: 15px;
}

.groupeinput{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:15px ;
    margin-bottom: 20px;
    &.big{width: 100%;}
}

.equipeajoutmodif_managers{display: flex; flex-wrap: wrap; gap: 15px;  margin-bottom: 25px;}
.notmanager{  width: 15px; height: 15px; cursor: pointer; 
                  background-image: url(./../checkboxbase.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;} 
.ismanager{width: 15px;height: 15px;
cursor: pointer;   background-image: url(./../checkboxvalid.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;}

.equipeajoutmodif_onemanager{height: 15px; line-height: 15px;width: 31%; display: flex;
              &_texte{ padding-left: 10px;
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                color:   #344054; }
         }