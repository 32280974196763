
$colortrait :  #EAECF0;
$colorline1 :  #344054;
$colorline2 :  #667085;
$colorlink :  #FF9123;

.histories{   max-height:calc(100vh - 260px) ;  overflow-y: scroll;  padding: 0 15px ;
    &::-webkit-scrollbar {display: none; }  ; 
    &_container{
        position : relative ;  
    }
    &_line{
        position: absolute;  left: 22px;  top: 20px ; bottom: 0px;  width: 2px;   background-color: #EAECF0; z-index: 0; 
    }
}

.history{
    display:flex ;
    margin : 12px 0 ;
    justify-content: flex-start ;
    &_date{font-style: normal; font-weight: 400; font-size: 10px;line-height: 18px; height: 18px; color:$colorlink;}
    &_img{width : 46px; height: 46px;margin-right: 15px ; border-radius: 50%;  background-color: #FFF;  background-position: center center;background-repeat:no-repeat ; background-size: 40px 40px; z-index: 10;}
    &_description{width: 630px; height: auto;  overflow-x: hidden;  }
    &_texte{line-height: 20px;}
    &_enterlead{background-image:url(../../images/history/iconenterlead.svg)}
    &_appelsortant{background-image:url(../../images/history/iconenterlead.svg)}
    &_appelmanque{background-image:url(../../images/history/iconappelmanque.svg)}
    &_appelentrant{background-image:url(../../images/history/iconappelsortant.svg)}
    &_appelsortant{background-image:url(../../images/history/iconappelsortant.svg)}
    &_note{background-image:url(../../images/history/iconnoteajoute.svg)}
    &_rdvfixe{background-image:url(../../images/history/iconrdv.svg)}
    &_rdvmodifie{background-image:url(../../images/history/iconrdv.svg)}
    &_relancefixe{background-image:url(../../images/history/iconrelance.svg)}
    &_relancemodifie{background-image:url(../../images/history/iconrelance.svg)}
    &_emaildeviscrmsend{background-image:url(../../images/history/iconemail.svg)}
    &_emaildeviscrmopen{background-image:url(../../images/history/iconemail.svg)}
    &_email{background-image:url(../../images/history/iconemail.svg)}
    &_emailonboarding{background-image:url(../../images/history/iconemail.svg)}
    &_resiliation{background-image:url(../../images/history/iconemail.svg)}
    &_emailliensignaturecrmsend{background-image:url(../../images/history/iconemail.svg)}
    &_emailliensignaturecrmopen{background-image:url(../../images/history/iconemail.svg)}
    &_qualiflead{background-image:url(../../images/history/iconqualifylead.svg)}
    &_signature{background-image:url(../../images/history/iconqualifylead.svg)}
    &_documents{background-image:url(../../images/history/icondocuments.svg)}
    &_leadgagne{background-image:url(../../images/history/icongagne.svg)}
    &_signature{background-image:url(../../images/history/iconsignature.svg)}
    &_conseil{background-image:url(../../images/history/iconsignature.svg)}
    &_changecommercial{background-image:url(../../images/history/iconqualifylead.svg)}
    &_ticket{background-image:url(../../images/history/iconticket.svg)}
    &:last-child{position:relative ;
            &::after{content:'';position: absolute;width: 46px ; top:0 ; bottom  : 0     ;background: #FFF;  }
       
    }

}


.horoline1{height: 20px;font-family: 'Poppins';font-style: normal;font-weight: 500;font-size: 14px;color:$colorline1 ;}
.horoline2{font-size: 12px;line-height: 14px;color: lightgray ;}
.horoline2memo{font-size: 14px;line-height: 20px;color: $colorline2 ;}
.horobold{ display: inline-block;
  color:  #FF9123;
  font-weight: 500;
}
 a {
    color:  #FF9123;
    font-weight: 500;
  }
  


.historique{
    height:calc(100vh - 160px) ;
    padding: 20px;
    position: relative;
}


.horodatage_container{
    margin-top: 10px;
    height:calc(100vh - 350px) ;
    overflow: scroll;
    &::-webkit-scrollbar {   display: none;}
    margin-bottom: 10px;
}



.horocol1{width: 130px;}
.horocol2{width: 230px;}

.horodatageline{
    height: 20px ; 
    td{padding:0 10px 0 0;}
}