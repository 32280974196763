
.groupregime{
    height: 65px;
    padding-top: 20px;
    // width: 350px;
    position: relative;

    &_buttons{
        display: flex;
        gap: 10px; 
        justify-content: space-between;
        align-items: baseline;
         &::before{ content:"Regime"; position:absolute; width: 200px;height: 14px;line-height: 14px;left: 0px;top:0px;
                   z-index: 10;color: #344054;font-size: 14px;font-weight: 600; }

    }

}