.ecrangestionreassigner{
    width: 692px;
    background-color: #FFF;
    position: fixed;
    top: 100px;
    left: 50%;
  transform: translateX(-50%);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    background-position:center top 25px ; background-repeat: no-repeat;background-size: 56px; 
    background-image: url(../../images/iconreassigner.svg);
    padding-top: 100px;
    &_titre{font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            color: #101828;
            &.center{text-align: center;}
    }
    &_texte{font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #344054;
            margin-top: 15px;
            &.center{text-align: center;}
    }
    &_btns{
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
}

.selectcom{
  margin-top: 8px;
  width: 200px;
  height: 35px;
  border-radius: 6px  ;
  border: 1px solid lightgray;
  padding: 0 5px;
  outline:none ;
  &_option{
    border-bottom: 1px solid lightgray;
  }
}