/* src/WYSIWYGEditor.css */

.wysiwyg-editor2 {
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.toolbar {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.toolbar button {
  padding: 5px 10px;
  cursor: pointer;
}

.editor2 {
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 200px;
  outline: none;
}

.italic{font-style: italic;}
.underline{text-decoration: underline;}
.bold{font-weight: bold;}