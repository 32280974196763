.groupeimgcompagnies{
    display: flex;
    height : 50px; 
}


.container_imgcompagnies{
  margin-right: 4px ; 
    height: 44px;
    width: 44px;
    cursor: pointer;
    position: relative;
    img{border-radius: 50%;  pointer-events: none;}
}
.casevalidall{cursor: pointer;
    width: 18px; height: 18px; margin: auto 10px auto 0px;
    background-position:center; background-repeat: no-repeat;background-size: 18px; 
    background-image: url(../images/checkbox.svg);
    &.validate{ background-image: url(../images/checkboxvalid.svg);}
 }

.compagnievu{
    width: 15px;
    pointer-events: none;
    height: 15px;
    border-radius: 50%;
    background-color: #FF8001;
position: absolute;
top:0;
right: 0;
background-image: url(../images/selectcomp.svg);
background-position: center 4px;  background-repeat: no-repeat; background-size: 10px ;
&_selected{opacity: 0;}
}

.checkbox{
    width: 15px;
    height: 15px;
}