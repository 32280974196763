.popupradinconfirm{
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width:1200px;
    height: 338px;
    padding: 100px 20px 60px 20px;
    border-radius: 12px;
    background: #FFFFFF;
    background-position: center  top 40px ;
    background-repeat: no-repeat;
    background-size:40px 40px ;
    background-image: url(../../images/fuseeverte.svg);

    &_titre{font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #101828;
        margin-bottom: 80px;
}
  &_texte{font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #667085;
        width: 500px;
        margin: 0 auto;
        span{  color:#3084FF; font-weight: bold; }
  }

}