
.uploadlogo{
    position: relative;
    width: 338px;
    min-height:  104px ;
    display: flex;
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 8px;

    z-index:1; 
    background-repeat: no-repeat; background-size: 40px 40px;
    &.uploadwait{ padding: 59px 24px   16px 24px   ;border: 1px solid #EAECF0;    flex-direction: column;
                 background-image: url(../../../images/uploadwait.svg);  background-position: center 15px ;  background-repeat: no-repeat;
                  &.hover{ background: #FFF9F3;border: 1px solid #FFB265; background-image: url(../../../images/uploadwaitorange.svg);   background-position: center 15px ;  background-repeat: no-repeat; }  }
    &.uploaded{background-image: url(../../../images/upload.svg);  background-position: 10px 10px;  padding: 16px 10px   16px 60px   ;border: 1px solid #FF9123;
              &.hover{ background: #FFF9F3;background-image: url(../../../images/upload.svg); background-repeat: no-repeat; background-position: 10px 10px;  padding: 16px 10px   16px 60px   ;border: 1px solid #FF9123;}}
}




.uploadlogo_input{
    border : 1px solid red ;
    position: absolute;left:0px;  top:0 ;   opacity: 0; 

    z-index: 2;
  width: 85% ;  height: 100%;
  cursor: pointer; 
  &.little{
    height: 45px; width:100% ; 
  }
}



.uploadlogo_2{

    z-index: inherit;
    &_top{display:inline-block ;  font-size: 12px;color: #344054; pointer-events: none ; 
        &.line1{font-size: 14px;  span{ font-weight: 500; color:   #FF9123;}}
        &.line2{font-size: 10px; }
        &.uploadwait{width:100%  ;   text-align:center  ;  } 
        &.uploaded{margin-bottom: 10px ; width: 115px;   }
    }
       
    &_bottom{display: flex;  justify-content: space-between;  width:220px ; 
            a{font-weight: 500;font-size: 14px;color:  #667085; padding: 0px 5px;width: 200.16px;background: #F2F4F7;border-radius: 8px;}
        }
    span{position: relative; z-index: 2;}
}


.validcontrat_linkdoc{height: 20px; display: flex;  align-items: center;  justify-content: space-between;margin-bottom: 3px; }
.uploadlogo_progress_container{
    height: 2px;
    position: absolute ;
    bottom: 5px;
    left: 10px; 
    width:calc(100% - 20px) 
}
.uploadlogo_progress{
    height: 2px;
    bottom: 5px;
    left: 10px; 
    background-color: #FF9123;
    &.uploadwait{display: none ; }
}

// @for $value from 0 through 100 {
//     .lenght#{$value} {
//         width: calc(percentage($value / 100));

//       }
// }

.uploaddel{
    cursor: pointer;
    z-index: 3;
}

.uploadcontratlink{
    z-index: 13;  position: relative;
    display: inline-block;    background-color: #F2F4F7;border-radius: 6px;
    font-weight: 400;font-size: 14px;line-height: 20px;color: #667085; padding-left: 5px;
    overflow: hidden  ;

}
