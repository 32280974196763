.divdateahesion{display: flex; justify-content: space-between;}

.openclosefiltres{
    display: inline-block; padding: 0px 14px; color:#344054; cursor: pointer;
     font-size: 12px;font-weight: 500;  height: 40px;  line-height: 40px;
     padding-left: 42px;  color: #344054; font-size: 12px;
    border-radius: 8px;border: 1px solid  #D0D5DD;background:   #D0D5DD;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background-image: url(../../images/filtresopenclose.svg);background-position:left 18px center;  
    background-repeat: no-repeat;background-size: 15px 15px;
    &.first{margin-right: 10px;}
}