@import 'TitleInput.scss'; 
@import 'Button.scss'; 
@import 'Infos.scss'; 




.ecranformulaire{
    padding: 70px 0px;
    position: relative;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    background-color:#FFF ;  
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    width:900px;
    height: 80vh;

}
.ecranformulaire_top {
    position: absolute;
    height:67px ;
    line-height: 67px;
    background-color: #FFF;
    padding: 0 15px;
    top: 0;
    border-bottom: 1px solid  #EAECF0;
    border-radius: 8px 8px  0 0 ; 
    font-size: 18px;
    color: #101828;
    z-index: 1000;
    width: 900px;
   img{
     position: absolute;
     right: 15px;
     top: 30px;
     cursor: pointer;
   }
 
 }

