.mimitd{
    padding:0 6px;
    text-align: center;
    border:1px solid gray;

}

.mimitable{margin:40px auto 0px auto  ; }

.mimibtn{ height: 40px;line-height: 40px;  padding:0px 10px 0px 10px ; display: inline-block;
    border-radius: 8px;border:  1px solid #D0D5DD;
    cursor: pointer;font-size: 14px;font-weight: 500;
    color: #344054; margin-right: 10px;
    &.selected{background-color: #344054; color: #FFF;}
} 


.ecranmimi{
    width: 1000px;
    min-height: 500px;
   padding: 20px;
    background-color: #FFF;
    position: fixed;
    top: 100px;
    left: 50%;
  transform: translateX(-50%);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
   

}