.ticketmodal{ margin: 100px auto ; width: 400px; border-radius: 6px; background-color: #FFF;  }
.ticketmodal_container{ position: fixed; top: 0px;  left: 0; width: 100%;  height: 100vh;     background-color: #10182880; }
.ticketmodaltop{padding:24px ;  border-bottom: 1px solid#EAECF0;line-height: 18px; }

.ticketmodaltop_titre_grp{
   display: flex;   justify-content: space-between;  height: 40px;   margin-bottom: 10px;  padding-left: 30px;    
   background-repeat: no-repeat;background-size: 21px 21px; 
   background-image: url(../../images/tickettel.svg);background-position: left  0px center;  
}


.ticketmodaltop_titre{
  
    line-height: 40px;  text-align: left; font-weight: 400;  font-size: 10px;color: #FF9123; 

}

.ticketmodal_texte{ padding:24px;   border-bottom: 1px solid#EAECF0;line-height: 18px;}
.ticketmodal_texte_titre{font-weight: 700; font-size: 14px;color: #475467}
.ticketmodal_texte_content{ font-weight: 500;font-size: 12px;color: #667085;}

.ticketmodal_change{display: flex; justify-content: space-between;  height: 20px; margin-top: 6px;
                    &_texte{ height: 20px; line-height: 20px;font-size: 12px;  color: #475467;} 
                    &_btn{ cursor: pointer;  height: 20px; line-height: 20px; color: #FFF;font-size: 12px;   background-color: #FF9123; border-radius: 6px; padding: 0 8px; } 
                }