

.formuledetail_labelinput{ width:calc(50% - 10px) ; position : relative ;
    input{width: 100%;  padding: 10px 14px;gap: 8px;height: 44px; line-height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px; outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;       
        }  }

.formuledetail_label{height: 20px;font-family: 'Poppins';font-style: normal;font-weight: 500;font-size: 14px;line-height: 20px;color: #344054; margin-bottom: 5px; }

.formuledetail_btn{
    margin-top: 10px;
    display: flex;
    justify-content: right;
}