

.calendartwodatepickers{

    width: 464px;
    background-color:#FFF ;  
   display: flex;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
   border-radius: 12px;overflow: hidden;  margin: auto;
}

.onepicker{
    width: 232px;
    padding: 10px;
    background-color: #FFF;
    border-right: 1px solid #EAECF0; 
}
.onepicker_titre{text-align: center; font-weight: 500;font-weight: bold;  font-size: 16px;color: #344054; letter-spacing: 2px; }
.onepicker_top{height: 20px;
    padding: 0 11px;
    display: flex;
    justify-content: space-between;
    div{line-height: 20px;}
    &_middle{font-weight: 500; font-size: 12px;color: #344054; }
    #back, #next{ width:10px;background-position:center center; background-repeat: no-repeat;background-size: 5px 10px; }
    #back{cursor:pointer;  background-image: url(../../images/calendrierback.svg);
           &.noback{opacity: 0; cursor: none ;  pointer-events: none;}}
    #next{cursor:pointer; background-image: url(../../images/calendriernext.svg);}
}


.onepicker_pickercontainer{
    display: flex;  justify-content: center; align-items: center; 
}
.onepicker_picker{  display: flex; flex-wrap: wrap;  padding: auto; 
    div{width: 30px; height: 30px; line-height: 30px;  text-align: center; cursor: pointer;  position: relative;}
    .today{ 
        &::after{content:'';position: absolute;width: 5px;height: 5px;left: calc(50% - 5px/2);bottom: 2px;background: #E47200;  border-radius: 50%; }
      }
      .selected0{ color: #FFF; background: #E47200;  border-radius: 50%; }
      .selected{ color: #FFF; background: #E47200;  border-radius: 50%; }
    .disable{opacity: 0.5; cursor: none ;  pointer-events: none; }
}

.day2lettres{color: #344054;  font-weight: 700;font-size: 11px}

.casetwodates{ font-size: 11px; &:hover{ color: #FFF; background: #E47200;  border-radius: 50%; } }