.jeresilie{

    position: relative;
    top: 10vh;
    background-color:#FFF ;  
    width: 650px;
   height: auto;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
   border-radius: 12px;overflow: hidden;  margin: auto;
    padding: 70px 15px 20px 15px ;
    background-position:center 15px;  background-repeat: no-repeat;
    background-size: 40px 40px;
    background-image: url(../../images/icon_comgestion.svg);
    &_titre{
        color:  #101828;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 150% */
    }

}


.gendergroup{
    height: 69px;
    padding-top: 26px;
    width: 195px;
    position: relative;
    &_buttons{
        height: 44px  ;
        display: flex;
        gap: 10px;
        // justify-content: space-between;
        align-items: baseline;
         &::before{ content:"Titre"; position:absolute; width:122px;height: 20px;line-height: 20px;left: 0px;top:0px;
                   z-index: 10;color: #344054;font-size: 14px;font-weight: 500; }

    }

}