.container_filtresformules{
    position: relative;
    margin-top: 6px;
    margin-bottom: 10px;
    z-index: 10;
    background-color: #FFF;
    transition: all   0.3s;

}
.croixclosefiltres{
    width: 10px; height: 10px; 
}
.containercroix{
    width: 12px; height: 12px;line-height: 12px;  position: absolute;  right:20px; top:5px; cursor: pointer; z-index: 7000;
}
.filtresformules{
    width: 703px;
    margin: auto;
    display: flex;
    gap:10px;
    flex-direction: column;
    border-radius:8px ;
    justify-content: space-between;
    background-color: #F2F4F7;
    overflow: hidden;
    padding: 10px ;
    transition: all   0.3s;

}
.errordatedepartement{position: absolute; top:0px ; height: 12px; line-height: 12px; font-size: 11px; color: red; font-weight: bold;  }
.filtres_containerdate{
    display: inline-block;
    width: 250px;
    height: 42px;
}
.filtresformules_datedisplays{
    display: flex;  justify-content: space-between;
}



.btn{ 
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 12px;
    outline: none;
    cursor: pointer; 

    &.first{
       border-radius:  8px 0 0 8px ; 
    }
    &.last{
        border-radius:  0 8px 8px 0; 
     }
     &.unique{
        border-radius:  8px  8px 8px  8px ; 
    }
    &_besoins{
        background: #FFF ;
        border-top: 1px solid #D0D5DD;
        border-bottom: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left:none;
        color: #344054;
        &.first{
            border-left: 1px solid #D0D5DD;
         }
        &.selected{
            background-color: #EAECF0;
        }
    }
    &_compagnies{
        border-top: 1px solid #8EBCFF;
        border-bottom: 1px solid #8EBCFF;
        border-right: 1px solid #8EBCFF;
        border-left:none;
        color:#015DE2;
        background-color: #FFF ;
        &.first{
            border-left: 1px solid #8EBCFF;
         }
        &.selected{
            background-color: #ECF4FF;
        }
    }
    
    &_choixdate{
        border-top: 1px solid #E47200;
        border-bottom: 1px solid #E47200;;
        border-right: 1px solid #E47200;;
        border-left:none;
        color:#E47200;;
        background-color: #FFF ;
        &.first{
            border-left: 1px solid #E47200;;
         }
        &.selected{
            background-color: #E47200;
            color: #FFF; 
        }
    }
    
    &_nb{display: inline-block;
        height: 20px;
        width: 20px;
        line-height: 20px;
        border: 1px solid blue;
        border-radius: 50%;
        font-size: 8px;
        margin-left: 5px;
        text-align: center;
        
    }
} 