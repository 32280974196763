.infos{
    background-color: #FFF;
  
}
.firstline{
color :  #101828;
font-weight: bold;
margin-bottom: 0px;
display: flex;
justify-content: space-between;
}
.btndelchild{
    color: red;
    cursor: pointer;
}
.btninversion{cursor: pointer;
    height:36px; width: 48px; 
    background-position: center; background-repeat: no-repeat;background-size: 48px  36px; 
    background-image: url(../../images/btninverse.svg);
}
.groupinfos{
    padding: 10px;
    display: flex;
    justify-content:flex-start; 
    flex-wrap: wrap;
    align-items: baseline ;
    gap: 10px;
    width:100%;
    // height: 40px;
    // padding: 10px;
    // margin-bottom: 10px;
    &.commun{
        background-color: #F2F4F7;;
    }
}
.infossouscripteur{
    background-color: #EAECF0;
    padding: 10px;
    margin-bottom: 10px;
    .lineinfossouscripteur{
        display: flex;
        justify-content:flex-start; 
        // flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 5px;
    }
}

.formulairedateeffet{
    height:  40px ;
    line-height:  40px ;
    display: flex;
    position: relative;
    &_titre{
        position: absolute;
        top:-30px; 
        color: #344054;
        font-size: 14px;
        font-weight: 600;
    }

}