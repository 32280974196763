
.cardformule{
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    margin: 0 auto 15px  auto;
    z-index:1;
}

.cardformuletop{
   cursor: pointer;
    height: 110px;
    display: flex; 
    align-items: center;
    z-index:1;
    padding-left: 10px;
}

.etiquette{
    height: 14px ;
    line-height: 14px ;
    font-weight: 600;
    font-size: 10px;
    border-radius: 5px;
    display: inline-block;
    padding: 0 7px;
    &_container{height: 18px; display: flex}
    &.lowcost{background-color:#FDF2FA; color:#F387D3 }
    &.lineaire{background-color:#DCEAFF ; color:#53B1FD }
}

.cardformulebottom{
    padding: 10px;
}
.cardformulecheckformulesigne{width: 30px;  display: flex; justify-content: right; align-items: center;
        div{
            width: 20px; height: 20px; background-position: center center;
            background-repeat: no-repeat; background-size: 20px 20px;
            background-image: url(../../images/checkboxfalse.svg);
            &.checked{ background-image: url(../../images/checkboxtrue.svg);}
        }
}


.nomformule{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 215px;
    &_span1 , &_span2{display:block; line-height: 16px; }
    &_span1{font-weight: bold; font-size: 14px;}
    &_span2{font-size: 12px;}
}



.cardformuleimage{ 

    text-align:center;
    padding: 15px; 
     border-radius: 6px 0 0 6px;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
    img{ display: inline-block; border-radius: 50%; border: 1px solid #ECECEC;  vertical-align:middle;  margin:auto }

}


.cardformuleright{
    width: 105px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position:relative;  
    text-align:right; 
    background-color: #FFF;  
    border-radius:0 6px 6px 0;
   &_span1{display : block ; color: #4a4a4a;  display: block;  font-weight: bold; line-height: 20px;  text-align: right; height: 24px; font-size: 24px;
           }
    &_span2{display : block ; color: #4a4a4a;  display: block;height: 10px; text-align: right;font-size: 12px;   font-family: Poppins; line-height: 10px; }

      
} 

.cardformule_last{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}






.poppinsbold{
    font-family: Poppins-bold;
}