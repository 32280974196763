
.dashboardtop{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 23px;
    margin-bottom: 0px;

}


.dashbouton{
    div{pointer-events: none;}
    width: calc((var(--divleads-width) - 72px) /4 );
    height: 70px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between ; 
    padding:12px 15px ;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    cursor: pointer;
    img{ pointer-events: none;}
    &_col1{display: flex; flex-direction: column;  justify-content: space-between;  align-items: flex-start; }
    &_titre{color: #667085;font-size: 12px;font-style: normal;font-weight: 500;line-height: 12px;}
    &_nb{color:  #101828; font-size: 24px;font-style: normal;font-weight: 600;line-height: 46px;}
}
.dashboutonchoice{
background-color: #F2F4F7;  }