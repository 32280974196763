.minilistedesevents{
    border-right: 1px solid#EAECF0; 
    width: 270px;
    height: 628px;
    overflow: scroll;
    &::-webkit-scrollbar {   display: none;}
}



.miniformatjour{
    font-size: 12px;
    font-weight: 500;
    color: gray;
    padding-left: 15px;
    height: 30px;
    line-height: 30px;
    background-color: #F2F4F7;
}

.miniformatrdv{
    overflow: hidden;
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid #EAECF0;
    &_nopro {background-color:#FFF;}
    &_nopro {background-color:#F5FBFF;}
    height: 30px;    line-height: 30px;
    &_heuremin{ color: #667085;  font-weight: 400;padding-left: 10px;
        font-size: 12px;  text-align:left; width: 50px;  }
}

.minisummary{ position: relative ;  padding-left: 12px;  width:  235px; font-size: 12px;color: #101828;font-weight: 500  ; }