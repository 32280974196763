

.contrats_section_titre{
    
    color:  #101828;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
    }