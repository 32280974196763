
@import '../EcranParametres.scss' ;

.divider{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom:   1px solid  #EAECF0;
}

.parametrage_soustitre{height: 20px; font-family: 'Poppins'; font-style: normal; font-weight: 500; font-size: 14px; line-height: 20px; color: #344054;}
.parametrage_para{font-weight: 400; font-size: 14px;line-height: 20px;  color: #667085;}

.parametrage_tablo{width: 1150px;background: #FFFFFF;border: 1px solid #EAECF0;
    border-radius: 8px;margin-top: 30px;   
     box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
     &_line1{background-color: #F9FAFB;height:44px ; display: flex;border-top-right-radius : 8px ; border-top-left-radius : 8px ; 
                 div{line-height:44px ; font-weight: 500;font-size: 12px; color: #667085;}
                 & > div:nth-child(1){width :240px ;padding-left: 15px;}
                 & >   div:nth-child(2){width :406px ;}
                 & >    div:nth-child(3){width :400px; display: inline-flex ; &>.first{width: 80px;} &>.second{width: 60px; text-align: center;} }
                 & >   div:nth-child(4){width :52px ;}
                 & >   div:nth-child(5){width :52px ;}
     }       
     &_line{background-color: #FFF;height:44px ; display: flex;
        div{line-height:44px ; font-weight: 500;font-size: 12px; color: #667085;}
        &:last-child{border-bottom-right-radius : 8px ; border-bottom-left-radius : 8px ; }
        & > div:nth-child(1){width :240px ;padding-left: 15px;}
        & > div:nth-child(2){width :406px ;}
        & > div:nth-child(3){width :400px; display: inline-flex ; &>.first{width: 80px;}
                              &>.nomanager{width: 60px;  height: 44px;  text-align: center;   
                                         // background-image: url(./../checkboxbase.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;
                                        } 
                              &>.ismanager{width: 60px;height: 44px;text-align: center;   
                                              background-image: url(./../checkboxvalid.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;}
                             }
         
                            
        & > div:nth-child(4){width :52px ;cursor: pointer;   background-image: url(./../dump.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;  }
        & > div:nth-child(5){width :52px ;cursor: pointer;   background-image: url(./../pen.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px; }
}         
}

.ajouteruser{cursor:pointer ; 
    height: 50px; line-height: 50px;  padding-left: 30px ;font-weight: 500; font-size: 14px;color: #667085;
    background-image: url(../../../images/ajoutcomplus.svg);background-position: left center;  background-repeat: no-repeat;
    background-size: 16px 16px;     cursor: pointer;
}
