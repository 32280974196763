

.tabloauthsutilisateurs{
    width: 920px;background: #FFFFFF;border: 1px solid #EAECF0;
    height: calc(100vh - 480px); overflow-y: scroll;
   border-radius: 8px;margin-top: 30px;   margin-bottom: 30px;   
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    &_line1{background-color: #F9FAFB;height:52px ; display: flex;border-top-right-radius : 8px ; border-top-left-radius : 8px ; 
                div{line-height:52px ; font-weight: 500;font-size: 12px; color: #667085;}
                & > div:nth-child(1){padding-left: 15px;  width :170px ;}
                & > div:nth-child(2){width :250px; }
                & > div:nth-child(3){width :130px ;}
                & > div:nth-child(4){width :200px ;}
                & > div:nth-child(5){width :84px ;text-align : center }
    }    
    &_line{background-color: #FFF;height:52px ; display: flex;border-top-right-radius : 8px ; border-top-left-radius : 8px ; 
       div{line-height:52px ; font-weight: 500;font-size: 12px; color: #667085;}
                & > div:nth-child(1){padding-left: 15px; width :170px ;}
                & > div:nth-child(2){width :250px; }
                & > div:nth-child(3){width :130px ;}
                & > div:nth-child(4){width :200px ;}
                & > div:nth-child(5){width :84px ;line-height: 52px;}
   }    
   &_divgamme{border-bottom: 1px solid lightgray;}
   &_lines{max-height:calc(100vh - 300px);overflow-y: scroll;&::-webkit-scrollbar {display:none;} }
}