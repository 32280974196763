.utilisateurs_tablo{
 
     width: 1350px;background: #FFFFFF;border: 1px solid #EAECF0;
    border-radius: 8px;margin-top: 30px;  
     box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
     &_line1{background-color: #F9FAFB;height:44px ; display: flex;border-top-right-radius : 8px ; border-top-left-radius : 8px ; 
                 div{line-height:44px ; font-weight: 500;font-size: 12px; color: #667085;}
                 & > div:nth-child(1){width :210px ;padding-left: 15px;}
                 & > div:nth-child(2){width :240px ;overflow-wrap: break-word;}
                 & > div:nth-child(3){width :120px; }
                 & > div:nth-child(4){width :80px ;text-align: center;}
                 & > div:nth-child(5){width :60px ;text-align: center;}
                 & > div:nth-child(6){width :235px ;}
                 & > div:nth-child(7){width :65px ;}
                 & > div:nth-child(8){width :65px; text-align: center;}
                 & > div:nth-child(9){width :65px ;text-align: center;}
                 & > div:nth-child(10){width :65px ;text-align: center;}
                 & > div:nth-child(11){width :49px ;}
                 & > div:nth-child(12){width :49px ;}
                 & > div:nth-child(13){width :49px ;}
     }    
     &_line{background-color: #FFF;height:44px ; display: flex;
       &_gray{background-color:#E9E9E9}
        div{line-height:44px ; font-weight: 500;font-size: 12px; color: #667085; border-bottom: 1px solid #FFF;}
        & > div:nth-child(1){width :210px ;padding-left: 15px;text-align: left;
                            color:   #101828; font-size: 14px; font-weight: 500;
                            line-height: 44px;}
        & > div:nth-child(2){width :240px ;font-size: 14px; font-weight: 400;
                            line-height: 44px;color:#667085;  overflow: hidden; }
        & > div:nth-child(3){width :120px;font-size: 14px; font-weight: 400;
                                line-height: 44px;text-align: center; color:#667085; text-align: left;}
        & > div:nth-child(4){width :80px ; }
        & > div:nth-child(5){width :60px ;}
        & > div:nth-child(6){width :235px ;}
        & > div:nth-child(7){width :65px ;}
        & > div:nth-child(8){width :65px; }
        & > div:nth-child(9){width :65px ;}
        & > div:nth-child(10){width :65px ;}
        & > div:nth-child(12){width :49px ;}
        & > div:nth-child(11){width :49px ;cursor: pointer;   background-image: url(./../dump.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;  }
        & > div:nth-child(12){width :49px ;cursor: pointer;   background-image: url(./../percent.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px; }
        & > div:nth-child(13){width :49px ;cursor: pointer;   background-image: url(./../pen.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px; }
    }    
    &_lines{max-height:calc(100vh - 300px);overflow-y: scroll;&::-webkit-scrollbar {display:none;} }
  }

.yes{ background-image: url(./../toogleyes.svg);background-position: center center;  background-repeat: no-repeat; background-size: 36px 20px;}
.no{ background-image: url(./../toogleno.svg);background-position: center center;  background-repeat: no-repeat; background-size: 36px 20px;}

.notrole{background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;} 
.isrole{background-image: url(./../checkboxvalid.svg);background-position: center center;  background-repeat: no-repeat; background-size: 15px 15px;}



.popupavertissement{
  position: fixed;top: 250px;left : 50% ;transform: translateX(-50%);
  width: 408px;border-radius: 12px;background:#FFFFFF;
  padding: 92px 24px 24px 24px;
  background-image: url(../../../images/alertdelete.svg);
  background-position: center 24px;  background-repeat: no-repeat; background-size: 48px 48px ;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  &_titre{height: 28px; font-weight: 500;font-size: 18px;line-height: 28px;text-align: center;color: #101828;}
  &_texte{height: 20px; font-weight: 400;font-size: 14px;line-height: 20px;text-align: center;color: #667085;}
  &_btns{display: flex; justify-content: center; gap: 10px; margin-top: 20px;}

}