.cardresilitation{
    position: relative;  border-radius: 12px; padding: 20px 20px 16px; gap: 23px; width: 589px; background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    margin: 50px auto; 
}


.cardresilitationclose{ position: absolute;right: 10px; top : 10px;  cursor: pointer;}

.cardresilitation_top{
    padding-top: 75px;background-position:  center top 10px ;  background-repeat: no-repeat; background-size: 48px 48px;
    &_titre{text-align: center;font-family: 'Poppins';font-weight: 500;font-size: 18px;line-height: 28px;color: #101828;}
    &_texte{text-align: center;font-family: 'Poppins';font-weight: 400;font-size: 14px;line-height: 20px;color: #667085;
           span{color :#667085  ; font-weight: bold;  } }
    &_debut{background-image: url(../../images/resiltop1.svg);}
    &_fin{background-image: url(../../images/resiltop2.svg);}
}

.cardresilitation_top_boulettes{display: flex;  justify-content: space-between; height: 8px;  width: 41px;  margin: auto;  margin-top: 15px;  margin-bottom: 20px; }
.boulette{ width: 8px;  height: 8px;  border-radius: 50%;   &.grise{  background-color: #ECF4FF; }   &.orange{  background-color:  #FF8001; }   }

.cardresilitation_content{

    &_titre{height: 28px;font-family: 'Poppins'; font-style: normal; font-weight: 500;font-size: 18px;line-height: 28px; color: #101828;  margin-bottom: 15px;}
}

.cardresilitation_form{ width: 100%;  display: flex; flex-wrap: wrap; justify-content:space-between}

.messagefin{font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px; text-align: center;color: #667085 ; margin: 30px 0; }
.cardresilurl{width: 100%;}
.linequestioninputradio{
 
    display: flex; justify-content: flex-start;  align-items: center;  height: 28px;  line-height: 28px;
    &_question{height: 20px;font-family: 'Poppins';font-style: normal;font-weight: 500;font-size: 14px;line-height: 20px;color: #344054;}
    &.half{ width: 50%;}  &.all{ width: 100%;}
}
.buttonradio{
    width: 16px;height: 16px; background: #FFFFFF;border: 1px solid #D0D5DD;border-radius: 8px; position: relative; cursor: pointer;  margin-right: 10px;
    &.validated{background: #FFEAD6;border: 1px solid #FF8001;
        &::after{content:'';position: absolute;width: 6px;height: 6px;left: 4px ; top:4px ;background: #E47200;  border-radius: 50%; }     
    }
}        

.inputtextelabel{height: 20px;font-family: 'Poppins';font-style: normal;font-weight: 500;font-size: 14px;line-height: 20px;color: #344054; margin-bottom: 5px; }

.inputretractation{width: 100%;  padding: 10px 14px;gap: 8px;height: 44px; line-height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px; outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;
                    }

.containerlabelinputresil{ &.big{width: 100%;}   &.small{width:calc(50% - 10px) ;} &.tiers{width:calc(33% - 8px)} &.deuxtiers{width:calc(66%) ;} }
.cardresilitation_buttons{display: flex; justify-content: center; gap:10px ; margin-top: 20px; }
                     