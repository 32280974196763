
.boutontelephonie{
    display: inline-block;
    border-radius: 8px;
    text-align: center;
    cursor: pointer; 
    padding: 25px 5px 5px 5px;
    height: 61px;
    background-position: center  top 5px ;
    background-repeat: no-repeat;
    background-size:19px;
    font-weight: 500  ;
    font-size: 14px ;
    line-height: 14px  ;
    span{color: inherit;}
 }

.boutontelephonie_interieur{
        display:flex;
        flex-direction: column; 
        align-items:center;
        justify-content: center;
        height:100% ;
        line-height:100% ;
        span{display:block;  color:#F3F4F6; line-height: 16px; height: 18px; }
}

.bouton_gestion{
    border: 1px solid  #7839EE;color: #7839EE ;background-size:18px;
     background-image: url(../../images/images_tel/icongestion.svg);
    &:hover{background-color:#7839EE; color:#FFF;  
               background-image: url(../../images/images_tel/icongestionwhite.svg);
    }
}



.bouton_nrp{
    border: 1px solid  #9DA4AE;color: #9DA4AE ;background-size:20px;
     background-image: url(../../images/images_tel/iconindispo.svg);
    &:hover{background-color:#9DA4AE ; color:#FFF;  
               background-image: url(../../images/images_tel/iconindispowhite.svg);
    }
}

.bouton_maintien{
    border: 1px solid  #0068FF;color: #0068FF ; 
     background-image: url(../../images/images_tel/iconmaintien.svg);
    &:hover{background-color: #0068FF ; color:#FFF;  
               background-image: url(../../images/images_tel/iconmaintienwhite.svg);
    }
}



.bouton_relance{max-width: 70px;
    border: 1px solid #FF8001;color: #FF8001 ;
     background-image: url(../../images/images_tel/iconrelance.svg);
    &:hover{background-color: #FF8001; color:#FFF; 
               background-image: url(../../images/images_tel/iconrelancewhite.svg);
    }
}
.bouton_rdv{max-width: 70px;
    border: 1px solid #FF8001;color: #FF8001; background-size:16px;
     background-image: url(../../images/images_tel/iconagenda.svg);
    &:hover{background-color:#FF8001 ; color:#FFF; 
               background-image: url(../../images/images_tel/iconagendawhite.svg);
    }
}


.bouton_fauxnum{
    border: 1px solid  #6C737F;color: #6C737F;
     background-image: url(../../images/images_tel/iconfauxnum.svg);
    &:hover{background-color:#6C737F ; color:#FFF;
               background-image: url(../../images/images_tel/iconfauxnumwhite.svg);
    }
}
.bouton_echec{
    border: 1px solid  #384250;color: #384250; 
     background-image: url(../../images/images_tel/iconechec.svg);
    &:hover{background-color: #384250 ; color:#FFF;  
               background-image: url(../../images/images_tel/iconechecwhite.svg);
    }
}

.bouton_gagne{
    border: 1px solid  #12B76A;color: #12B76A; 
     background-image: url(../../images/images_tel/icongagne.svg);
    &:hover{background-color: #12B76A ; color:#FFF;  
               background-image: url(../../images/images_tel/icongagnewhite.svg);
    }
}

