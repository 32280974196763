
// .ajoutdevis{
//    height: 18px;
//    line-height: 18px;
// }
svg{
display: inline-block;
}
.ajoute{
  color:lightblue;
}

.devis{
  color:#3084FF;
}

.svgdevis{
cursor: pointer;

&.selected{ 
      fill:#3084FF;
      stroke:#3084FF ;
    }
    &.noselected{
        fill:  #FFFFFF;
        stroke:  #667085;
    }

}

