.loadingupload{
    // opacity: 0.5;
    width: 20px;
    height: 20px;
    cursor: none;
    margin: auto;
    pointer-events: none;
      top: 10px;
      left: 10px;
      background: url("../../images/loadingorange.svg") no-repeat center/cover;
      animation: rotate 4s infinite linear;

  }


@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
@-webkit-keyframes rotate {
    from {-webkit-transform:rotate(0deg);}
    to {  -webkit-transform:rotate(360deg);}
  }
  
  @-moz-keyframes rotate {
    from {-moz-transform:rotate(0deg);}
    to {  -moz-transform:rotate(360deg);}
  }

  .contratwin_left_nobulletin{
    margin-bottom: 15px;
  }



.bulletin_choix{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #344054;
        height: 20px;
        line-height: 20px;
        vertical-align: top;
        margin-bottom: 15px;
        display: flex;
   
   span{display: inline-block; height: 20px;}

}
.bulletinfic_choix{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #344054;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
span{font-weight: bold;}

}

.ht10{
  height: 10px;
}

.bulletin_listedoc{
    padding-left: 20px;
    font-size: 14px;
    font-weight: 500;
    height: 25px;
    text-align: left;
    background-position: left center  ;
    background-repeat: no-repeat;
    background-size:16px 16px ;
    color: #344054; 

    line-height: 22px;
    background-image: url(../../images/vuorange.svg);
}

.contratwindevis_container_upload{
    width: 700px;
    border: 1px solid  #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
}


.contratwindevis_upload{
width: 670px;
position: relative;
height: 130px;
border: 1px solid  #D0D5DD;
border-radius: 8px;
background-color: #FFF;
background-image: url(../../images/uploadwait.svg);
background-repeat: no-repeat;
background-position:center 20px ;  
padding: 16px 10px   ;
margin:15px auto ;
&_texte{
    width: 100%;
    height: 110px;
    padding-top: 80px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #E47200;
}
}
.contratfic_box_top_texte{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #667085;
  margin-bottom: 5px;
}
.contratfic_flex{
  display: flex;
  &_middle1{  width: 30%; }
  &_middle2{  width: 70%; }
}

.fontbold{font-weight: bold;font-size: 15px;}

.contratwin_devis_containerformules{
width: 700px;
}
