.detailformule_container{
    position: absolute;
    width: 740px;
    padding: 0 15px 30px 15px ; 
    &.visible{visibility: visible;}
    &.nonvisible{ visibility: hidden;  }
}


.retourformules{
    font-weight: 500;font-size: 14px;color: #344054;    padding-left: 42px;width: 198px;
    height: 36px;  line-height: 36px;   background: #FFFFFF; border: 1px solid #D0D5DD; cursor: pointer;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);border-radius: 8px;  margin-bottom: 15px;
    background-image: url(../../images/retourformules.svg);background-position:left 18px center;  background-repeat: no-repeat;
    background-size: 12px 12px; 
  
}
.detailformule{ margin: auto; 
              height: auto;
                  background: #FFFFFF;
  
                border: 1px solid #EAECF0;
            
                 border-radius: 8px 8px 0px 0px;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
               
                                          
max-height: calc(100vh - 280px); 
                     overflow-y: scroll; &::-webkit-scrollbar {   display: none;}
               }

.detailformule_top{display: flex;
                    align-items: flex-start;
                    background-color: transparent;
                    border-bottom: 1px solid #EAECF0;
                    height: 112px;
                    &_1{  width: 124px; height: 112px;display: flex;flex-direction: column;align-items: center; justify-content: center; 
                        img{ display: inline-block; border-radius: 50%; border: 1px solid #ECECEC;  vertical-align:middle;  margin:auto }  }
                    &_2{ width: 240px; height: 112px;  display: flex;flex-direction: column;align-items: left; justify-content: center; 
                       .nomgamme{font-weight: 500;font-size: 18px; font-weight: bold; line-height: 18px; color: #101828; }    
                       .formulenom{height: 20px;font-weight: 400;font-size: 14px;line-height: 20px;color: #667085;}
                    }
                    &_3{  width: 128px; height: 112px;   display: flex;flex-direction: column;align-items: center; justify-content: center;
                        .prix{ height: 24px;line-height: 24px;font-weight: 700;font-size: 24px;color: #101828;  }
                        .parmois{height: 20px; font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 14px; line-height: 20px;color: #101828;}
                    }
                    &_4{  width: 140px; display: flex; justify-content: center; height: 110px;}
                    &_5{  width: 100px; height: 112px;   display: flex;flex-direction: column;align-items: center; justify-content: center;

                    }
                }
                .detailformule_bottom{     display:grid ;  grid-template-columns:1fr 1fr ; }
    .borderright{
        border-right: 1px solid  #EAECF0;
    }
    .containerjaugedetail{
        height:auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


    }
    .formuledetailsblock{
        display:grid ;
        grid-template-columns:33px 39px auto ;
        border-bottom: 1px solid  #EAECF0;
        padding:7px 15px;
        &.bigpadding{ padding:17px 15px;}
            &.last{
                border-bottom:none; 
            }
            &_divimage{
                display: flex;
                align-items: right;
                justify-content: center;
                img{display: block;}
            }
            &_texte{
                text-align: left;
                font-weight: 700;
                font-size: 14px;
            }
        } 
    .prevevide{
        height: 39px;
    }

    
    .formuledetailslineinfo{
        display: flex;
        justify-content: space-between;
        color:#4A4A4A;
        line-height: 20px;

        &_line1{
            text-align: left;
            font-size: 13px;
            &_bold{font-weight: bold;}
        }
        &_line2{
            text-align: left;
            font-size: 12px;
            font-family: Poppins-semibold;
            &_bold{font-weight: bold;}
        }

    } 
    
    
    .formuledetailstitre{
        margin-top: 15px;
        margin-bottom: 10px;
        text-align: left;
        font-size: 18px;
        font-family: Poppins-bold;
        color: var(--color_first) ;
    }  


       .minibtnsignature_mutuello{
          height: 44px;  width: 44px;  line-height: 44px;  margin-left: 10px;
       }

       .bigbtnsignature_mutuello{
        height: 44px;  line-height: 44px;  margin: 0 10px; padding: 0 15px; 
        img{margin-left: 10px;}
     }


     .imagepostedetail{
        background-position: left 15px center  ;
        background-repeat: no-repeat;
        background-size:32px 32px ;
    
        &.hospi{ background-image: url(../../images/images_medic/hospi.svg)}
        &.consults_specialistes{ background-image: url(../../images/images_medic/consults_specialistes.svg)}
        &.optique_lunettes{ background-image: url(../../images/images_medic/optique_lunettes.svg)}
        &.optique_lentilles{ background-image: url(../../images/images_medic/optique_lentilles.svg)}
        &.dentaire_prothese{ background-image: url(../../images/images_medic/dentaire_prothese.svg)}
        &.dentaire_implant{ background-image: url(../../images/images_medic/dentaire_implant.svg)}
        &.dentaire_orthodontie{ background-image: url(../../images/images_medic/dentaire_orthodontie.svg)}
        &.prevention{ background-image: url(../../images/images_medic/prevention.svg)}
    
    
    }
    