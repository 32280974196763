
.checkbullet{display: inline-block; width: 20px;height: 20px;line-height: 20px;
             cursor: pointer;  position: relative ; 

            &::before{ content: url('../images/checkboxfalse.svg');
                    position: absolute;   width: 20px; height: 20px; }
            
            &.checked{ &::before{ content: url('../images/checkboxtrue.svg');}}

            &.ml25{margin-left: 25px;}
            &.mr10{margin-right: 10px ; }
}
