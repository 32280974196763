



.containerdatepicker{
     position: absolute;
     left: 215px;
     top: 50px;
     z-index: 2000;

}



    .react-datepicker__day--names{
      height: 28px;
    }
  
    .react-datepicker__day--today{
      background-color:#FFF !important ;
      color: blue   !important ;
      border: 1px solid  blue   !important
    }
        