.ficheclientcontainer{
    display: flex;
    justify-content: space-between;
}
.clientsgestion{

    padding: 10px 24px 60px 24px;
    height: calc(100vh);
    &_bottom{
        position: absolute;
        bottom: 0;
        background-color: #FFF;
        bottom: 0;
        left: 0;
        height: 60px;

        width: 100%;
        border-top: 1px solid  #EAECF0;
        border-radius:  0 0 8px 8px; 
        text-align: right;
        padding: 10px 15px;
        button{
        
            border-radius: 8px;
            border: 1px solid #ff8001;
            color: #FFF;
            background-color: #ff8001;
            font-size: 14px;
            font-weight: 400;
            height: 40px;
            line-height: 40px;
            text-align: center;
            padding: 0 15px; 
            margin-bottom: 0px;
            cursor: pointer; }
    }
}


.containerupload{
    height: 95vh;
    padding:  10px;
    min-width:223px ;
    max-width:446px ;
    display: flex; flex-wrap: wrap; gap: 18px;
    max-height: 95vh;  overflow-y: scroll ;&::-webkit-scrollbar {display: none; }
     @media screen and (max-width: 1600px) {width: 206px;}
}