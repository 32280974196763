

.ficeditableeprix{ display: inline-block; 
    margin-left: 6px;
     width: 60px;
      height: 20px;
       line-height: 20px; 
       outline: none; 
       padding-right: 5px;
        text-align: right;
        background-color: #EAECF0;}  

.ficheconseil_textetop{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
       color: #667085;
       margin-bottom: 8px;

}

.contratwinfic_titrelisteformule{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    padding-left:  20px;
    text-align: left;
    color: #344054;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 15px 15px;  
    background-image: url(../../images/vuorange.svg);
}


.contratwinfic_lineformule{
    text-align: left;
    padding-left: 15px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    line-height: 24px;
    color: #344054;
    span{padding: 0 2px;}
}


.ficheconseil_box_top_texte{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #667085;
}

.ficheconseil_box_top_textesmall{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #D0D5DD;

}




