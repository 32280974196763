
    // .svgcolor1{ &_noselect{fill:#4B4A4A}    &_selected{fill:#FFF}}
    // .svgcolor0{ fill: #FFF;   &_noselect{fill:#4B4A4A20;}    &_selected{fill:transparent}}

    .svgcolor1{   &_selected{fill:#FFF}}
    .svgcolor0{   &_selected{fill:transparent}}


    .svggris1{fill: #141414}
    .svggris0{fill:#EDEDED; }


    
    .svgorange1{fill: var(--color_second); }
    .svgorange0{fill: var(--color_second); opacity: 0.2;}


