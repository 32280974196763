.periodes {
    padding-left: 15px;
    height: 68px;
    line-height: 68px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}


 .periodes_groupe {
    width: auto;
    height: 6px;
    display: flex;
    justify-content: space-between;
 
}

.periodes_now{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    width:auto;
    height: 38px;
    line-height: 38px;
    background: #FFD5AA;
    border-radius: 6px; 
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
text-align: center;
background-color:#FFD5AA;
color: #FF9123;
span{display: inline-block; height: 20px; line-height: 20px; width: 20px;  border-radius: 50% ; font-size: 14px; 
    background-color: #FF9123; color: #FFD5AA; text-align: center;}
    

}




.periodes_autre{
    padding: 0 8px;
    color: grey; 
    display: flex; align-items: center;  justify-content: space-between;
    span{display: inline-block; height: 20px; line-height:22px; padding: 0 0px;  min-width: 20px;  border-radius: 10px ; font-size:12px; 
         background-color: grey;  color: #FFF; text-align: center; margin-left: 10px;}
         &_texte{font-size: 12px;  cursor : pointer}
}