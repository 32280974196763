$calendrier_height: 628px;
$calendrier_width: calc(935px);


.calendrier{
    position: relative;
    top: 10vh;
    background-color:#FFF ;  
    width: $calendrier_width;height: $calendrier_height;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
   border-radius: 12px;overflow: hidden;  margin: auto;
}


.calendrier_top{   
    height: 68px;background: #FFFFFF;  border-bottom: 1px solid #EAECF0;
    padding: 0 15px; display: flex; justify-content: space-between;
    &_texte{ line-height: 68px;font-size: 18px;color: #101828;}
    &_close{width: 60px; cursor: pointer;  background-image: url(../../images/croixclose.svg);
            background-position: right center;  background-repeat: no-repeat; background-size: 12px 12px;}
}

.calendrier_content{ display: grid; grid-template-columns: auto 1fr 1fr;position: relative;
                     & > div{height:calc(#{$calendrier_height} - 68px) ;}
}
.calendrier_contenttwodates{
    position : absolute;
    top: 30px;
    width: 330px;
    background-color:#FFF ;  
   
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
   border-radius: 12px;overflow: hidden;  margin: auto;
    display: grid; grid-template-columns:  1fr;
   
}
.casetwodates{&:hover{ color: #FFF; background: #E47200;  border-radius: 50%; } }
.horaire{padding: 20px 24px;background-color: #FFF;

        &_liste{   overflow-y: scroll ;&::-webkit-scrollbar {display: none; } ; height:calc(#{$calendrier_height} - 218px)  ;}
        
        .heure{height: 40px;background: #F2F4F7;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);border-radius: 8px;
                font-size: 14px; line-height: 40px;color: #344054;   margin-bottom: 12px ; text-align: center; cursor: pointer;  }

        .heure_confirme{display: flex;   justify-content: space-between;   
                        &_hmin{width: 135px;height: 40px; line-height: 40px; color:#FFF; font-size: 14px;   background: #344054; border: 1px solid #344054;
                                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);border-radius: 8px;  text-align: center; margin-bottom: 10px ;  } 
        }

        &.no{opacity: 0; pointer-events: none;  background-color: transparent;}
}
.heure_confirme_btn{
    width: 134px;height: 40px;background: #FF8001;border: 1px solid #FF8001;
    font-weight: 500;font-size: 14px; line-height: 40px;color: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);border-radius: 8px;
    text-align: center;cursor: pointer;}
// <div className='heure_confirme'><div className='heure_confirme_hmin' >{x}</div> <div  className='heure_confirme_btn'>Confirmer</div></div>
.datepicker{
    padding: 20px 24px;
    background-color: #FFF;
    border-right: 1px solid #EAECF0; 
}
.datepicker_titre , .horaire_titre{height: 40px;line-height: 40px; font-weight: 500; 
                font-size: 18px;text-align: center;color: #667085;}

.horaire_date{height: 40px;line-height: 40px; font-weight: 500; font-size: 18px;text-align: center;color:  #344054; margin-bottom: 20px;}

.datepicker_top{height: 40px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    div{line-height: 40px;}
    &_middle{font-weight: 500; font-size: 16px;color: #344054; }
    #back, #next{ width:10px;background-position:center center; background-repeat: no-repeat;background-size: 5px 10px; }
    #back{cursor:pointer;  background-image: url(../../images/calendrierback.svg);
           &.noback{opacity: 0; cursor: none ;  pointer-events: none;}}
    #next{cursor:pointer; background-image: url(../../images/calendriernext.svg);}
}

.day2lettres{color: #344054;  font-weight: 700;font-size: 14px}

.datepicker_pickercontainer{
    display: flex;  justify-content: center; align-items: center; 
}
.datepicker_picker{  display: flex; flex-wrap: wrap;  padding: auto; 
    div{width: 40px; height: 40px; line-height: 40px;  text-align: center; margin:4px 0 ; cursor: pointer;  position: relative;}
    .today{ 
        &::after{content:'';position: absolute;width: 6px;height: 6px;left: calc(50% - 5px/2);bottom: 2px;background: #E47200;  border-radius: 50%; }
      }
      .selected0{ color: #FFF; background: #E47200;  border-radius: 50%; }
      .selected{ color: #FFF; background: #E47200;  border-radius: 50%; }
    .disable{opacity: 0.5; cursor: none ;  pointer-events: none; }
}



.divcommentaire{
    position: absolute;
    z-index: 13;
    left: 0; right: 0; top: 0; bottom: 0;
    background-color: #FFF;
    padding: 32px;
    display: grid;
    grid-template-columns: 4fr 5fr;
    &_right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
}

.commentaire{
    width: 100%;
    height: 350px;
    border: 1px solid #EAECF0;
    border-radius: 8px;
    color:  #667085;
    padding: 20px;
    outline: none;
}