

.modifcontrat{
    position: relative;  border-radius: 12px; padding: 20px 20px 16px; gap: 23px; width: 692px; background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    margin: 50px auto; 
}


.modifcontratclose{ position: absolute;right: 10px; top : 10px;  cursor: pointer;}

.modifcontrat_form{ width: 100%;  display: flex; flex-wrap: wrap; gap: 18px;}
.modifcontrat_titre{text-align: center;font-family: 'Poppins';font-weight: 500;font-size: 22px;line-height: 28px;color: #101828;margin-bottom:  20px;}


.selectmofistatutcontrath{
    outline: none;
    color: lightgray;
    border: 1px solid lightgray; 
    border-radius: 4px;
}

.selectmofistatutcontrat{width: 100%;  padding: 10px 14px;gap: 8px;height: 44px; line-height: 44px; background: #FFFFFF;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px; outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;       
    }