.equipement{
    position: relative;margin: 50px auto; 
    width: 692px;
    // height: 908px;
    padding: 80px 20px 16px 20px;
    gap: 23px;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px #10182808;
    background-position: center top 20px;
    background-repeat: no-repeat; background-size: 50px 50px;
    background-image: url(../../images/equipement.svg);

    &_titre1{   font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                text-align: center;
                margin-bottom: 50px ;
                color: #101828;
            }
    &_titre2{ font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                text-align: left;
                color: #101828;
                margin-bottom: 15px ;

    }
    &_files{

        position: relative;
        min-height:124px;
        border: 1px solid  #D0D5DD;
        border-radius: 8px;
        background-color: #FFF;
        background-image: url(../../images/uploadwait.svg);
        background-repeat: no-repeat;
        background-position:center 20px ;  
        padding: 10px   ;
        padding-top:40px ; 
        margin:15px auto ;
        &_texte{
            width: 100%;
            height: 110px;
            padding-top: 80px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #E47200;
        }
        &_liste{
            width: 60%;
            margin: auto;
            display: flex;
            justify-content: center ; 
            gap: 6px ; 
            flex-wrap: wrap ; 
        }
    }

    &_boutons{
 
        display: flex;
        justify-content: center;
        gap: 10px;       
    }
   
}

.equipement_formulaire{
    // width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}
.containerlabelandselect{
    margin-bottom: 15px;
    &.half{    width:calc(50% - 10px) ;  }
    &.large{    width:100% ;  }
    &.third{width:calc(33% - 10px) ; }
  
}
.containerlabelandselect_label{
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #344054;
            margin-bottom: 4px;
}

.containerlabelandselect_select{

    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0 4px;
    input{width: 100%;  padding: 10px 14px;gap: 8px;height: 42px; line-height: 44px; background: #FFFFFF;border: none;
        outline: none;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #667085;       
        } 
    }

.selectcat{width:calc(100% - 10px) ;  height: 42px;border: none; background: #FFFFFF; outline: none;
           font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;
           color: #667085;    }

.equipement_listefiles{ overflow-wrap: break-word;
    min-height: 18px ; line-height:18px ; margin-bottom: 4px;
    a{font-size: 10px; color: #667085; margin-right: 8px; }
}