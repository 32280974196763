.container_screenmails{
    padding-top: 50px; 
    height: 100vh;
    display: flex;
    padding: 38px 32px;
}

.attachementlink{
    font-style: normal;font-weight: 500;font-size: 12px;line-height: 18px;color: gray;
    display: inline-block ;  padding-left:15px ;  margin-right: 15px;
    background-image: url(../../images/messagelinkgray.svg);
    background-position: 0px center;  background-repeat: no-repeat;
    background-size: 12px 12px;
}

.ecranmails_left{
    width: 405px;
    padding-right: 25px;
    &_top{  display: flex; padding: 20px 0 ; gap: 8px ; 
            &_btn{height: 38px;  line-height: 38px;   padding: 0 12px;  color: #667085 ; cursor: pointer;
                  color: #344054; font-family: Poppins;font-size: 14px;
                  font-style: normal;font-weight: 500;border-radius: 6px;
                  &.btnchoiced{  background:  #F2F4F7; color: #344054 ;   }
            }     
    }
    &_liste{ height: calc(100vh - 210px); 
             overflow-y: scroll;
             &::-webkit-scrollbar { display: none; } 
    }
}


.ecranmails_left_search{
    display: inline;
    margin-right: 10px;
    padding-left: 36px;
    height: 44px;
    line-height: 44px;
    width:calc(100% - 50px) ;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    outline: none;
    background-position: 10px 50%;  
    background-size: 20px;
    background-image: url("../../images/search.svg");
    background-repeat: no-repeat;
    color:   #667085;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

}


.mailbutton{
    display: flex;
    padding: 20px 0px 20px 20px;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    margin-bottom: 8px;
    // div:not(.mailfavori){ pointer-events: none;}
    cursor: pointer;
    &_initiales{ width: 56px;height: 56px; line-height: 56px; border-radius:28px; text-align: center;  background:  #3084FF;color: #FFF; }

    &_details{padding-left: 24px;width: 290px;  position: relative;
        &_recordtype{position: absolute; right: 0px; top:0px ; padding-left: 10px;
            background-position: left center;  background-repeat: no-repeat;
            background-size: 10px 10px;  
         } 
        &_time{color: #667085; font-family: Poppins;font-size: 10px; font-style: normal;font-weight: 400; line-height: 18px;}
        &_subject{color:  #101828;  font-family: Poppins; font-size: 14px;  font-style: normal;  font-weight: 600;  line-height: 15px; height: 30px;  overflow: hidden;  }
        &_nomprenom{color: #667085;  font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 600;line-height: 20px; }
    }


    &.emailnonlu{background-color: #F2F4F7}

    &.emailselected{background-color: #D0D5DD;}
  
}


.deliverymail{ background-image: url(../../images/emails/delivery.svg); color: gray; font-size: 9px;}
.subscribemail{ background-image: url(../../images/emails/rejected.svg); color: red ; font-size: 9px;}
.openmail{ background-image: url(../../images/emails/open.svg); color: #59D499 ; font-size: 9px;}
.bouncemail{ background-image: url(../../images/emails/rejected.svg); color: red ; font-size: 9px;}
.complaintmail{ background-image: url(../../images/emails/rejected.svg); color: red ; font-size: 9px;}
.bouncemail{ background-image: url(../../images/emails/rejected.svg); color: red ; font-size: 9px;}
.errormail{ background-image: url(../../images/emails/errormail.svg); color: red ; font-size: 9px;}
.successmail{ background-image: url(../../images/emails/send.svg); color: #59D499 ; font-size: 9px;}
.unknowntypemail{color: #999 ; font-size: 9px;}

.ecranmails_right{
    width: calc(100% -  400px) ;    position: relative;  background-color:#ECF4FF ;
    &_top{position : relative  ; display: flex;padding: 20px 24px 19px 0px;align-items: center;gap: 16px;align-self: stretch; background-color: #FFF; height: 107px; 
            &_texte{color: #101828;font-family: Poppins;font-size: 18px;font-style: normal;font-weight: 500;}
     }
    &_gotolead{position: absolute;  right: 20px; width: 35px;  height: 35px;     background-image: url("../../images/gotoleadimage.svg");    background-repeat: no-repeat;
               cursor: pointer;      background-size: 35px;  }
     &_fromto{position: absolute ; bottom: 0; left :70px ; font-size: 10px; font-weight: bold;}

}
.nbmailsnonouverts{
    display: inline-block;   width: 24px; height: 24px; line-height: 24px; border-radius: 50%; background-color: #667085; font-size: 12px; font-weight: bold;  color: #344054;text-align: center; margin-left: 5px;
}

.ecranmails_right_bottom{
  
    height: calc(100vh - 183px);
    padding: 24px 24px 92px 24px ;
    &_email{ height: calc(50vh - 100px);    overflow-y: scroll; &::-webkit-scrollbar { display: none; } 
         background-color: #FFF;  padding:28px ;border-radius: 8px ;border: 1px solid  #D0D5DD;
        &_titre{color: #101828;font-family: Poppins;font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px;}
        &_time{ color:  #667085; font-family: Poppins;font-size: 10px;font-style: normal;font-weight: 400;line-height: 18px;}
        &_content{color:   #344054;  font-family: Poppins;font-size: 14px;font-style: normal;font-weight: 500;line-height: 20px;    word-wrap: break-word;}
    }
    &_reponse{
        height: calc(50vh - 280px); width: 100%;   overflow-y: scroll; &::-webkit-scrollbar { display: none; } 
        background-color: #FFF;  padding:28px ;border-radius: 8px ;border: 1px solid  #D0D5DD; outline: none ;

    }
    &_btncontainer{
        display: flex;
        height: 80px;
        justify-content: flex-end;
        align-items: center;
    }


}
