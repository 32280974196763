.buttonsend{
        position: relative;
        border-radius: 8px;
        text-align: center;
        cursor: pointer; 
        font-weight: 500; 
        padding: 0 40px  0 10px; 
        background-position:right 10px center; 
        background-repeat: no-repeat;
        background-size: 18px ;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #FFF;
        &.color_blanc_vert{
          border: 1px solid  #12B76A ;
          color:  #12B76A ;
          background-color: #FFF;
        }
        &.color_orange{ background-color:  #ff8001;  }
        &.color_blanc_orange{ background-color:  #ffF; color:#ff8001; border: 1px solid #ff8001}
        &.color_blue{ background-color:   #3084FF; }
        &.color_disable{background-color: lightgrey;pointer-events: none;}

        &.loading{
            padding-right:40px;
            opacity: 0.5;
            cursor: none;
            pointer-events: none;
            &::before {
              content: '';
              width: 20px;
              height: 20px;
              position: absolute;
              top: 10px;
              right: 10px;
              background: url("../../images/loadingwhite.svg") no-repeat center/cover;
              animation: rotate 4s infinite linear;
            }
          }

          
        &.loading{
          padding-right:40px;
          opacity: 0.5;
          cursor: none;
          pointer-events: none;
          &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
            background: url("../../images/loadingorange.svg") no-repeat center/cover;
            animation: rotate 4s infinite linear;
          }
        }
}